// icon
import PlayCircle from "@material-ui/icons/PlayCircleFilled";
import { frontPageSectionStyle } from "assets/jss/material-kit-pro-react/views/frontPageSections/frontPageSectionStyle.jsx";
// @material-ui/core components
import classNames from "classnames";
// core components
import Button from "components/CustomButtons/Button.jsx";
import React from "react";
import { Link } from "react-router-dom";


export function CtaSection(props) {
    const classes = frontPageSectionStyle()
    const bgColor = { backgroundColor: props.CtaBgColor };
    const textColor = { color: props.CtaBgColor };

    return (
        <div className={`${classes.contacts} ${classes.ctaSection}`} style={bgColor}>
            <div className={classes.container}>
            
                <h2 className={classes.title}>
                    {props.CtaText}
                </h2>
                <h2 className={classes.description}>
                    {props.CtaDescription}
                </h2>

                <Link to={props.CtaUrl} style={textColor} target="_blank">
                    <Button round className={classNames(classes.autoMargin, classes.dlBtn)} color="white" aria-label={props.CtaBtn} style={textColor}>
                        <h5 className={classes.dlButtonLabel}>
                                {props.CtaBtn}  <PlayCircle className={classes.dlIcon} />
                        </h5>
                    </Button>
                </Link>
                <div>
                    <small className={classes.subtitle}>{props.CtaSubText}</small>
                </div>
            </div>
        </div>
    );
}