import React from "react";
import { Link } from "react-router-dom";

// nodejs library that concatenates classes
import classNames from "classnames";

// nodejs library to set properties for components
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

// @material-ui/icons
import Menu from "@material-ui/icons/Menu";
import Close from "@material-ui/icons/Close";

// core components
import headerStyle from "assets/jss/material-kit-pro-react/components/headerStyle.jsx";

import { withTranslation } from 'react-i18next';

class Header extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            mobileOpen: false,
            langOpen: false,
            i18n: props.i18n
        };
        this.handleDrawerToggle = this.handleDrawerToggle.bind(this);
        this.langDrawerToggle = this.langDrawerToggle.bind(this);
        this.headerColorChange = this.headerColorChange.bind(this);
        this.logoRef = React.createRef();
    }

    
    handleDrawerToggle() {
        this.setState({ mobileOpen: !this.state.mobileOpen });
    }
    
    langDrawerToggle() {
        this.setState({ langOpen: !this.state.langOpen });
    }
    
    componentDidMount() {
        if (this.props.changeColorOnScroll) {
            window.addEventListener("scroll", this.headerColorChange);
        }
    }
    
    headerColorChange() {
        const { classes, color, changeColorOnScroll } = this.props;
        const windowsScrollTop = window.pageYOffset;
        if (windowsScrollTop > changeColorOnScroll.height) {
            document.body
                .getElementsByTagName("header")[0]
                .classList.remove(classes[color]);
            document.body
                .getElementsByTagName("header")[0]
                .classList.add(classes[changeColorOnScroll.color]);

            this.logoRef.current.src = "/fs-logo.png";
        } else {
            document.body
                .getElementsByTagName("header")[0]
                .classList.add(classes[color]);
            document.body
                .getElementsByTagName("header")[0]
                .classList.remove(classes[changeColorOnScroll.color]);

            this.logoRef.current.src = "/fs-logo.png";
        }
    }
    
    componentWillUnmount() {
        if (this.props.changeColorOnScroll) {
            window.removeEventListener("scroll", this.headerColorChange);
        }
    }

    handleChangeLanguage(e) {
        e.preventDefault();
        this.state.i18n.changeLanguage(e.target.getAttribute('data-value'));
        this.langDrawerToggle();
    }

    render() {
        const { classes, color, links, fixed, absolute, i18n} = this.props;
        const appBarClasses = classNames({
            [classes.appBar]: true,
            [classes[color]]: color,
            [classes.absolute]: absolute,
            [classes.fixed]: fixed
        });
    
        return (
            
            <AppBar className={classNames(appBarClasses, classes.mui-fixed)}>
            
                <Toolbar className={classNames(classes.container, classes.mui-fixed)}>
                    <Button aria-label="back to index" className={classes.title}>
                        <Link aria-label="index" to="/" style={{fontWeight: "400"}}><img ref={this.logoRef} className={classes.mttLogo} src="/fs-logo.png" alt="FeedStudio" /></Link>
                    </Button>
                    <Hidden smDown implementation="css" className={classes.hidden}>
                        <div className={classes.collapse}>
                            
                            {links}
                            
                            <List className={classNames(classes.list, classes.mlAuto, classes.langSwitcher)}>
                                <ListItem className={classes.listItem} aria-label="language" >
                                    <Button className={classes.dropdownLinkButton} onClick={this.langDrawerToggle}>
                                    {i18n.language.toUpperCase()}
                                    </Button>
                                </ListItem>
                            </List>
                            
                        </div>
                    </Hidden>
                    
                    <Hidden mdUp>
                        <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={this.handleDrawerToggle}
                        >
                            <Menu />
                        </IconButton>
                    </Hidden>
                </Toolbar>
                
                <Hidden mdUp implementation="css">
                    <Drawer
                        variant="temporary"
                        anchor={"right"}
                        open={this.state.mobileOpen}
                        classes={{
                            paper: classes.drawerPaper
                        }}
                        onClose={this.handleDrawerToggle}
                    >
                        <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={this.handleDrawerToggle}
                        className={classes.closeButtonDrawer}
                        >
                            <Close />
                        </IconButton>
                        <div className={classes.appResponsive}>
                            {links
                            }
                            <List className={classNames(classes.list, classes.mlAuto, classes.langSwitcher)}>
                                <ListItem className={classes.listItem} aria-label="language" >
                                    <Button className={classes.dropdownLinkButton} onClick={this.langDrawerToggle}>
                                    {i18n.language.toUpperCase()}
                                    </Button>
                                </ListItem>
                            </List>
                            
                        </div>
                    </Drawer>
                </Hidden>

                <Drawer
                    variant="temporary"
                    anchor={"top"}
                    open={this.state.langOpen}
                    classes={{
                        paper: classes.drawerPaperTop
                    }}
                    onClose={this.langDrawerToggle}
                    >
                    <IconButton
                        color="inherit"
                        aria-label="open lang drawer"
                        onClick={this.langDrawerToggle}
                        className={classes.closeButtonDrawer}
                    >
                        <Close />
                    </IconButton>
                    <div className={classNames(classes.appResponsive, classes.langListContainer)}>
                        
                        <GridContainer>
                            <GridItem lg={5} xs={12}>
                                <img src={require("assets/img/mtt/world-map.png")} className={classNames(classes.imgResponsive, classes.worldMap)} alt="World Map" />
                            </GridItem>
                            <GridItem lg={7} xs={12}>
                                
                                <ul className={classNames(classes.listUnstyled, classes.langList, classes.multiColumn3)}>
                                    <li><Button data-value="ar" onClick={(e) => this.handleChangeLanguage(e) }>Arabic</Button></li>
                                    <li><Button data-value="zh_CN" onClick={(e) => this.handleChangeLanguage(e) }>Chinese</Button></li>
                                    <li><Button data-value="cs" onClick={(e) => this.handleChangeLanguage(e) }>Czech</Button></li>
                                    <li><Button data-value="en" onClick={(e) => this.handleChangeLanguage(e) }>English</Button></li>
                                    <li><Button data-value="fr" onClick={(e) => this.handleChangeLanguage(e) }>French</Button></li>
                                    <li><Button data-value="de" onClick={(e) => this.handleChangeLanguage(e) }>German</Button></li>
                                    <li><Button data-value="ga_IE" onClick={(e) => this.handleChangeLanguage(e) }>Irish</Button></li>
                                    <li><Button data-value="it" onClick={(e) => this.handleChangeLanguage(e) }>Italian</Button></li>
                                    <li><Button data-value="hi_IN" onClick={(e) => this.handleChangeLanguage(e) }>Hindi</Button></li>
                                    <li><Button data-value="hu" onClick={(e) => this.handleChangeLanguage(e) }>Hungarian</Button></li>
                                    <li><Button data-value="ms" onClick={(e) => this.handleChangeLanguage(e) }>Malay</Button></li>
                                    <li><Button data-value="pl" onClick={(e) => this.handleChangeLanguage(e) }>Polish</Button></li>
                                    <li><Button data-value="pt_BR" onClick={(e) => this.handleChangeLanguage(e) }>Portuguese - Brazil</Button></li>
                                    <li><Button data-value="ru" onClick={(e) => this.handleChangeLanguage(e) }>Russian</Button></li>
                                    <li><Button data-value="sk" onClick={(e) => this.handleChangeLanguage(e) }>Slovak</Button></li>
                                    <li><Button data-value="es" onClick={(e) => this.handleChangeLanguage(e) }>Spanish</Button></li>
                                    <li><Button data-value="tr" onClick={(e) => this.handleChangeLanguage(e) }>Turkish</Button></li>
                                    <li><Button data-value="uk" onClick={(e) => this.handleChangeLanguage(e) }>Ukrainian</Button></li>
                                </ul>
                                
                            </GridItem>
                        </GridContainer>
                        
                    </div>
                </Drawer>

            </AppBar>

        );
    }
    
}

Header.defaultProp = {
    color: "white"
};

Header.propTypes = {
    classes: PropTypes.object.isRequired,
    color: PropTypes.oneOf([
        "primary",
        "info",
        "success",
        "warning",
        "danger",
        "transparent",
        "white",
        "rose",
        "dark"
    ]),
    links: PropTypes.node,
    brand: PropTypes.string,
    fixed: PropTypes.bool,
    absolute: PropTypes.bool,
    // this will cause the sidebar to change the color from
    // this.props.color (see above) to changeColorOnScroll.color
    // when the window.pageYOffset is heigher or equal to
    // changeColorOnScroll.height and then when it is smaller than
    // changeColorOnScroll.height change it back to
    // this.props.color (see above)
    changeColorOnScroll: PropTypes.shape({
        height: PropTypes.number.isRequired,
        color: PropTypes.oneOf([
            "primary",
            "info",
            "success",
            "warning",
            "danger",
            "transparent",
            "white",
            "rose",
            "dark"
        ]).isRequired
    })
};

export default withStyles(headerStyle)(withTranslation()(Header));
