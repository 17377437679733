import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import headerLinksStyle from "assets/jss/material-kit-pro-react/components/headerLinksStyle.jsx";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
// react components for routing our app without refresh
import { Link } from "react-router-dom";
import Button from "components/CustomButtons/Button"


class SlimHeaderLinks extends React.Component {
    
    easeInOutQuad(t, b, c, d) {
        t /= d / 2;
        if (t < 1) return c / 2 * t * t + b;
        t--;
        return -c / 2 * (t * (t - 2) - 1) + b;
    };


    scrollGo(element, to, duration) {
        var start = element.scrollTop,
            change = to - start,
            currentTime = 0,
            increment = 20;

        var animateScroll = () => {
            currentTime += increment;
            var val = this.easeInOutQuad(currentTime, start, change, duration);
            element.scrollTop = val;
            if (currentTime < duration) {
                setTimeout(animateScroll, increment);
            }
        };
        animateScroll();
    };

    smoothScroll(e, target) {
        if (window.location.pathname === "/sections") {
            var isMobile = navigator.userAgent.match(
                /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
            );
            if (isMobile) {
                // if we are on mobile device the scroll into view will be managed by the browser
            } else {
                e.preventDefault();
                var targetScroll = document.getElementById(target);
                this.scrollGo(document.documentElement, targetScroll.offsetTop, 1250);
            }
        }
    };

    
    
    
    render = () => {

        const { classes } = this.props;

        return (
            <List className={classes.list + " " + classes.mlAuto}>
                <ListItem key="feed_management" className={classes.listItem} aria-label="Feed Management" >
                    <Link to="/promotion" className={classes.dropdownLink} style={{color:"#00ada8"}}>Feed Management</Link>
                </ListItem>
                <ListItem key="earlybird" className={classes.listItem} aria-label="$1000 welcome credit" >
                    <Link to="/registration">
                        <Button round color="white" className={classes.dlBtn}>$1000 welcome credit</Button>
                    </Link>
                </ListItem>
            </List>
        );
    }
}

SlimHeaderLinks.defaultProps = {
    hoverColor: "primary"
};

SlimHeaderLinks.propTypes = {
    dropdownHoverColor: PropTypes.oneOf([
        "dark",
        "primary",
        "info",
        "success",
        "warning",
        "danger",
        "rose"
    ])
};

const mapStateToProps = state => {
    return {
        user: state.user
    }
}

export default connect(mapStateToProps)(withStyles(headerLinksStyle)(withTranslation()(SlimHeaderLinks)));
