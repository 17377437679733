import { makeStyles } from "@material-ui/core";
import {
    section,
    container,
    title,
    cardTitle,
    mlAuto,

} from "assets/jss/material-kit-pro-react.jsx";

export const frontPageSectionStyle = makeStyles({
    section,
    container,
    title,
    mlAuto,
    textTheme: {
        color: "#00ada8 !important"
    },
    yesIcon: {
        color: "#00ada8 !important"
    },
    mt0: {
        marginTop: "0 !important"
    },
    pt3: {
        paddingTop: "24px !important"
    },
    mb2: {
        marginBottom: "16px"
    },
    textCenter: {
        textAlign: "center"
    },
    cardTitle: {
        color: "#555555",
        textTransform: "uppercase",
        ...cardTitle
    },
    select: {
        background: "#fff",
        boxShadow: "0 -2px 3px 0px rgba(68, 68, 68, 0.1) inset, 0 0 2px 0px rgba(0, 0, 0, 0.1) inset, 0 8px 8px -5px rgba(68, 68, 68, 0.2) inset",
        borderRadius: "50px",
        height: "45px",
        padding: "0 20px",
        "&:before, &:hover:before, &:after": {
            borderBottom: "none !important",
        }

    },
    root: {
        "& $description": {
            color: "#555",
            fontSize: "1.5em"
        },
        "& table": {
            "& td": {
                borderBottom: "none !important",
                padding: "8px"
            }
        },
        "& button": {
            background: "linear-gradient(60deg,#00ada8,#00ada8)",
            color: "#FFFFFF",
            marginLeft: "auto",
            marginRight: "auto",
            boxShadow: "0 14px 26px -12px rgba(68, 68, 68, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(68, 68, 68, 0.2)",
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            letterSpacing: "1px",
            "& *": {
                ...title,
                color: "#fff",
                marginTop: "0",
                marginBottom: "0",
                fontSize: "1.2rem",
                lineHeight: "2",
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                fontWeight: "400"
            }
        },
        "& form": {
            "& > *": {
                marginBottom: "15px"
            },
            "& div": {
                width: "100%"
            },
            "& textarea": {
                "display": "block",
                "width": "100%",
                "minHeight": "80px",
                "background": "#fff",
                "boxShadow": "0 -2px 3px 0px rgba(68, 68, 68, 0.1) inset, 0 0 2px 0px rgba(0, 0, 0, 0.1) inset, 0 8px 8px -5px rgba(68, 68, 68, 0.2) inset",
                "borderRadius": "10px",
                "marginBottom": "15px",
                "border": "none",
                "resize": "none",
                "padding": "20px",
                "fontWeight": "300 !important"
            },
            "& input[type='text']": {
                display: 'flex',
                alignItems: 'center',
                width: "100%",
                background: "#fff",
                border: "0 none",
                borderRadius: "50px",
                padding: "0 20px",
                fontSize: "0.95rem",
                height: "45px",
                boxShadow: "0 -2px 3px 0px rgba(68, 68, 68, 0.1) inset, 0 0 2px 0px rgba(0, 0, 0, 0.1) inset, 0 8px 8px -5px rgba(68, 68, 68, 0.2) inset",
                "&::-webkit-input-placeholder": {
                    color: "#999",
                    opacity: "1"
                },
                "&::-moz-placeholder": {
                    color: "#999",
                    opacity: "1"
                },
                "&:focus::-moz-placeholder": {
                    opacity: "0"
                },
                "& div": {
                    marginLeft: "10px",
                    flex: 1,
                },
                "& button": {
                    padding: 10,
                    color: "#555"
                },
                "& hr": {
                    height: 28,
                    margin: 4,
                }
            }
        }
    },
    cardAvatarIcon: {
        boxShadow: "none !important",
        borderRadius: 0,
        filter: "drop-shadow(0 6px 5px rgba(0, 0, 0, 0.2))",
        "& img": {
            clipPath: "polygon(50% 0%, 94% 25%, 94% 75%, 50% 100%, 6% 75%, 6% 25%)"
        }
    },
    title2: {
        ...title,
        paddingTop: "20px",
        color: "#555555",
        textAlign: "center",
        paddingBottom: "40px",
        textTransform: "uppercase"
    },
    primaryColor: {
        color: "rgb(0, 173, 168)"
    },
    description: {},
    contacts: {},
    autoMargin: {},
    dlButtonLabel: {},
    dlBtn: {},
    dlIcon: {},
    subtitle: {},
    ctaSection: {
        ...section,
        padding: "40px 0",
        position: "relative",
        "& $container": {
            zIndex: "2",
            position: "relative",
            textAlign: "center"
        },
        "&:after": {
            position: "absolute",
            zIndex: "1",
            width: "100%",
            height: "100%",
            display: "block",
            left: "0",
            top: "0",
            content: "''",
            backgroundColor: "rgba(0, 0, 0, 0)"
        },
        "& $title": {
            color: "#FFFFFF"
        },
        "& $description": {
            color: "rgba(255, 255, 255, 1)",
            lineHeight: "1.3",
            fontSize: "2.0rem",
            marginTop: "0",
            marginBottom: "20px",
        },
        "& $contacts": {
            padding: "40px 0",
            backgroundColor: "#555"
        },
        "& $autoMargin": {
            marginLeft: "auto",
            marginRight: "auto"
        },
        "& $dlButtonLabel": {
            marginTop: "0",
            marginBottom: "0",
            fontFamily: `"Poppins", "Times New Roman", serif`,
            fontWeight: "600"
        },
        "& $dlBtn": {
            background: "linear-gradient(60deg,#fff,#fff)",
            color: "#555",
            boxShadow: "0 14px 26px -12px rgba(68, 68, 68, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(68, 68, 68, 0.2)",
            "&:hover": {
                color: "#555"
            }
        },
        "& $dlIcon": {
            marginLeft: "10px",
            marginTop: "-3px",
            height: "25px !important",
            width: "25px !important"
        },
        "& $subtitle": {
            color: "rgba(255, 255, 255, 1)",
            fontStyle: "italic"
        }
    },
    bgGray: {
        background: "#F3F3F3"
    },
    bgGrayGradient: {
        background: "linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(243,243,243,1) 100%)",
        filter: "progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f3f3f3',GradientType=0 )"
    },
    bulletpointList: {
        "& li:before": {
            content: '""',
            width: "5px",
            height: "5px",
            marginRight: "5px",
            background: "rgb(0, 173, 168)"
        }
    },
    channelsTable: {
        "& tr > th": {
            color: "#555"
        },
        "& tr > td, & tr > th": {
            borderBottom: "1px solid #ddd",
            lineHeight: "1.4",
            padding: "4px 0"
        },
        "& tbody > tr:last-child > td": {
            borderBottom: "0 none"
        },
        marginBottom: "10px"
    },
    visibleXs: {
        display: "none"
    },
    '@media (max-width: 480px)': {
        hiddenXs: {
            display: "none"
        },
        visibleXs: {
            display: "block"
        }
    }
}) 