//import FrontPage from "views/FrontPage/FrontPage.jsx";
import SlimFrontPage from "views/SlimFrontPage/SlimFrontPage.jsx";
import LoginPage from "views/LoginPage/LoginPage.jsx";
import LoginRedirectPage from "views/LoginPage/LoginRedirectPage.jsx";
import PrivacyPage from "views/PrivacyPolPage/PrivacyPolPage.jsx";
import TermsPage from "views/PrivacyPolPage/TermsPage.jsx";
import B2CPage from "views/B2CPage/B2CPage";
import { EarlyBirdRegistrationPage } from "views/EarlyBirdRegistrationPage/EarlyBirdRegistrationPage";

var indexRoutes = [
    { path: "/registration", name: "Early Bird Registration", component: EarlyBirdRegistrationPage},
    { path: "/privacy", name: "Privacy Policy", component: PrivacyPage},
    { path: "/terms", name: "Terms", component: TermsPage},
    { path: "/login/redirect", name: "LoginRedirectPage", component: LoginRedirectPage },
    { path: "/login", name: "LoginPage", component: LoginPage },
    { path: "/promotion", name: "Promotion", component: B2CPage },
    { path: "/", name: "FrontPage", component: SlimFrontPage }
];

export default indexRoutes;
