import React from "react";
// react components for routing our app without refresh
import { withStyles } from "@material-ui/core";
import classNames from "classnames";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';


import footerContentStyle from "assets/jss/material-kit-pro-react/components/footerContentStyle.jsx";


class FooterContent extends React.Component {
    

    constructor(props) {
        super(props)

        this.state = {
            lang: props.i18n.language,
            i18n: props.i18n
        }
    }

    handleChange = event => {

        this.state.i18n.changeLanguage(event.target.value)
        this.setState({ lang: event.target.value });
    };
    
    render() {
        
        const { classes } = this.props;
        return (
            <div>
              
                <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                        
                        <div className={classes.leftCol}>
                            <div>
                                <img className={classes.footerBrand} src="/fs-logo-white.png" aria-label="FeedStudio" alt="FeedStudio"/>
                            </div>
                            <div>
                                <p className={classes.about}>FeedStudio is enabling eCommerce stores to advertise effectively on Google Platforms. Created by marketers, for retailers - our goal is to make your marketing success easy and effortless. </p>
                                <p className={classes.copy}>
                                    &copy; 2020. <a href="https://eazy.digital" className={classes.linkWhite} target="_blank" rel="noopener noreferrer" aria-label="eazydigital">MTT Digital Kft.</a> &bull; All rights reserved 
                                </p>
                            </div>
                        </div>
                        
                    </GridItem>
                    <GridItem xs={12} sm={12} md={5}>
                        
                        <div className={classes.innerCol}>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={6}>
                                    <h4 className={classes.colTitle}>Navigation</h4>
                                    <List className={classes.colList}>
                                        <ListItem>
                                            <a href="https://wiki.feed.studio" className={classes.block} aria-label="FAQ">
                                                FAQ
                                            </a>
                                        </ListItem>
                                        <ListItem>
                                            <Link to="/terms" className={classes.block} aria-label="Terms and Conditions">
                                                Terms &amp; Conditions
                                            </Link>
                                        </ListItem>
                                        <ListItem>
                                            <Link to="/privacy" className={classes.block} aria-label="Privacy Policy">
                                                Privacy Policy
                                            </Link>
                                        </ListItem>
                                    </List>
                                </GridItem>
                            </GridContainer>
                        </div>
                        
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3}>
                        
                        <div className={classes.rightCol}>
                            <div className={classes.rightLinks}>
                                <h4 className={classes.colTitle}>Find Us On</h4>
                                <ul className={classes.socialIcons}>
                                    <li>
                                        <a href="https://twitter.com/FeedStudio" target="_blank" rel="noopener noreferrer" aria-label="twitter">
                                            <span className={classes.circleIcon}>
                                                <i className="fab fa-twitter" />
                                            </span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.facebook.com/love.feed.studio" target="_blank" rel="noopener noreferrer" aria-label="facebook" >
                                            <span className={classes.circleIcon}>
                                                <i className="fab fa-facebook-f" />
                                            </span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.linkedin.com/company/feed-studio/" target="_blank" rel="noopener noreferrer" aria-label="linkedin" >
                                            <span className={classes.circleIcon}>
                                                <i className="fab fa-linkedin-in" />
                                            </span>
                                        </a>
                                    </li>
                                </ul>
                                <form className={classes.langForm}>
                                <FormControl className={classNames(classes.formControl, classes.langswitchControl)} fullWidth>
                                    <Select
                                        className={classes.langSelect}
                                        value={this.state.lang}
                                        onChange={this.handleChange}
                                        inputProps={{
                                        name: 'language',
                                        id: 'language',
                                        
                                        }}
                                    >
                                        <MenuItem className={classes.langItem} value="ar">Arabic</MenuItem>
                                        <MenuItem className={classes.langItem} value="zh_CN">Chinese</MenuItem>
                                        <MenuItem className={classes.langItem} value="cs">Czech</MenuItem>
                                        <MenuItem className={classes.langItem} value="en">English</MenuItem>
                                        <MenuItem className={classes.langItem} value="fr">French</MenuItem>
                                        <MenuItem className={classes.langItem} value="de">German</MenuItem>
                                        <MenuItem className={classes.langItem} value="ga_IE">Irish</MenuItem>
                                        <MenuItem className={classes.langItem} value="it">Italian</MenuItem>
                                        <MenuItem className={classes.langItem} value="hi_IN">Hindi</MenuItem>
                                        <MenuItem className={classes.langItem} value="hu">Hungarian</MenuItem>
                                        <MenuItem className={classes.langItem} value="ms">Malay</MenuItem>
                                        <MenuItem className={classes.langItem} value="pl">Polish</MenuItem>
                                        <MenuItem className={classes.langItem} value="pt_BR">Portuguese - Brazil</MenuItem>
                                        <MenuItem className={classes.langItem} value="ru">Russian</MenuItem>
                                        <MenuItem className={classes.langItem} value="sk">Slovak</MenuItem>
                                        <MenuItem className={classes.langItem} value="es">Spanish</MenuItem>
                                        <MenuItem className={classes.langItem} value="tr">Turkish</MenuItem>
                                        <MenuItem className={classes.langItem} value="uk">Ukrainian</MenuItem>
                                    </Select>
                                </FormControl>
                            </form>
                            </div>
                        </div>
                        
                    </GridItem>
                </GridContainer>

            </div>  
        ) 
    }
}export default withStyles(footerContentStyle)(withTranslation()(FooterContent))