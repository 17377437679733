import { makeStyles } from "@material-ui/core";
import frontpagestyle from "assets/jss/material-kit-pro-react/views/frontPageStyle.jsx";
import Footer from "components/Footer/Footer.jsx";
import FooterContent from "components/Footer/FooterContent.jsx";
import SlimHeader from "components/Header/SlimHeader.jsx";
import SlimHeaderLinks from "components/Header/SlimHeaderLinks.jsx";
import React from "react";
import { Section2 } from "./Section/Section2";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

export function EarlyBirdRegistrationPage() {
    const classes = makeStyles(frontpagestyle)()
    const { t } = useTranslation()

    return (
        <div className={classes.root} style={{
            display: "flex",
            flexDirection: "column",
            minHeight: "100vh"
        }}>
            <Helmet>
                <title>{t("registration.meta.title")}</title>
                <meta name="description" content={t("registration.meta.desc.short")} />
                <link rel="canonical" href="https://feed.studio/registration" />
                <meta name="robots" content="index,follow" />
                <meta itemprop="name" content={t("registration.meta.title")}/>
                <meta itemprop="description" content={t("registration.meta.desc.short")}/>
                <meta itemprop="image" content="http://www.example.com/image.jpg"/>
                <meta name="twitter:card" content="http://example.com/image.jpg"/>
                <meta name="twitter:site" content="@twitter"/>
                <meta name="twitter:title" content={t("registration.meta.title")}/>
                <meta name="twitter:description" content={t("registration.meta.desc.long")}/>
                <meta name="twitter:creator" content="@twitter"/>
                <meta name="twitter:image:src" content="http://www.example.com/image.jpg"/>
                <meta property="og:title" content={t("registration.meta.title")}/>
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://feed.studio/registration" />
                <meta property="og:image" content="http://example.com/image.jpg" />
                <meta property="og:description" content={t("registration.meta.desc.short")} />
                <meta property="og:site_name" content="Feed Studio" />
            </Helmet>
            <SlimHeader brand="Feed.Studio"  fixed color="white" changeColorOnScroll={{ height: 200, color: "white" }} links={<SlimHeaderLinks dropdownHoverColor="info" />} />

            <div className={`${classes.main} ${classes.mainRaised} ${classes.pt8}`} >
                <Section2 />
            </div>
            <Footer theme="white" content={<FooterContent />} style={{
                flex: "1 1 auto"
            }}/>

        </div>
    )
}