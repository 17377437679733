import { TableCell, TableRow } from "@material-ui/core";
import { frontPageSectionStyle } from "assets/jss/material-kit-pro-react/views/frontPageSections/frontPageSectionStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import React from "react";
import { useTranslation } from "react-i18next";


export function Section3() {
    const { t } = useTranslation();
    const classes = frontPageSectionStyle()
    return (
        <div id="channels" className={`${classes.section} ${classes.bgGrayGradient}`} style={{marginBottom: "-55px", paddingBottom: "45px"}}>
            <div className={classes.container} style={{marginTop: "40px"}}>
                
                <h2 className={`${classes.title2} ${classes.primaryColor}`} style={{padding: "40px 0", fontSize: "3.25rem", color: "#00ADA8"}}>{t('home.secFeeds.title')}</h2>
            
                
                <GridContainer justify="space-evenly">
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                        <Card profile style={{minHeight: "340px", minWidth: "150px", margin: "0 0 30px"}}>
                            <CardBody>
                                <h3 className={classes.cardTitle}>Google</h3>
                                <table className={classes.channelsTable} style={{textAlign: "left", marginTop: "20px"}}>
                                    <thead>
                                        <tr>
                                            <th width="20%">Export</th>
                                            <th>Description</th>
                                            <th>Result</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className={classes.textTheme}><strong>Merchant Center feed</strong></td>
                                            <td>Sell products on Google Shopping</td>
                                            <td>Boost revenue by optimized field structure</td>
                                        </tr>
                                        <tr>
                                            <td className={classes.textTheme}><strong>Promotions feed</strong></td>
                                            <td>Show promotions to your customer</td>
                                            <td>Boost clickthrough rate &amp; Increase conversions</td>
                                        </tr>
                                        <tr>
                                            <td className={classes.textTheme}><strong>Local Inventory Ads feed</strong></td>
                                            <td>Show your products on Google Maps</td>
                                            <td>Boost offline revenue &amp; get new customers into your store</td>
                                        </tr>
                                        <tr>
                                            <td className={classes.textTheme}><strong>Product Ratings feed</strong></td>
                                            <td>Make your product ratings &amp; reviews available for users of Google</td>
                                            <td>Higher clickthrough rate</td>
                                        </tr>
                                        <tr>
                                            <td className={classes.textTheme}><strong>Dynamic Remarketing feeds</strong></td>
                                            <td>Show ads to people who have previously visited your website</td>
                                            <td>Boost revenue</td>
                                        </tr>
                                        <tr>
                                            <td className={classes.textTheme}><strong>Page feed</strong></td>
                                            <td>Page feed for Dynamic Search Ads</td>
                                            <td>Increase your search campaigns performance</td>
                                        </tr>
                                        <tr>
                                            <td className={classes.textTheme}><strong>Sitemap feed</strong></td>
                                            <td>URL feed for search engine optimization</td>
                                            <td>Increase your visibility in Google Search</td>
                                        </tr>
                                        <tr>
                                            <td className={classes.textTheme}><strong>Manufacturer feed</strong></td>
                                            <td>Provide better &amp; more accurate information about your products to Google</td>
                                            <td>Get comprehensive analytics & improve your presence on Google</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </CardBody>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={5} lg={4}>
                        <Card profile style={{minHeight: "443px", minWidth: "150px", margin: "0 0 30px"}}>
                            <CardBody>
                                <h3 className={classes.cardTitle}>Facebook</h3>
                                <table className={classes.channelsTable} style={{textAlign: "left", marginTop: "20px"}}>
                                    <thead>
                                        <tr>
                                            <th width="35%">Export</th>
                                            <th width="40%">Description</th>
                                            <th>Result</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className={classes.textTheme}><strong>Dynamic Ads feed</strong></td>
                                            <td>Show ads to people who have previously visited your website</td>
                                            <td>Boost revenue</td>
                                        </tr>
                                        <tr>
                                            <td className={classes.textTheme}><strong>Page Shop feed</strong></td>
                                            <td>Show &amp; sell products to people on Facebook</td>
                                            <td>Boost revenue</td>
                                        </tr>
                                        <tr>
                                            <td className={classes.textTheme}><strong>Marketplace feed</strong></td>
                                            <td>Show &amp; sell products to people on Facebook Marketplace</td>
                                            <td>Boost revenue</td>
                                        </tr>
                                    
                                    </tbody>
                                </table>
                            </CardBody>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={7} lg={8}>
                        <GridContainer justify="space-evenly">
                            <GridItem xs={12} sm={6} md={6} lg={6}>
                                <Card profile style={{minHeight: "140px", minWidth: "150px", margin: "0 0 30px"}}>
                                    <CardBody>
                                        <h3 className={classes.cardTitle}>Instagram</h3>
                                        <table className={classes.channelsTable} style={{textAlign: "left", marginTop: "20px"}}>
                                            <thead>
                                                <tr>
                                                    <th width="35%">Export</th>
                                                    <th width="45%">Description</th>
                                                    <th>Result</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className={classes.textTheme}><strong>Shopping<br/>feed</strong></td>
                                                    <td>Show &amp; sell products to people on Instagram</td>
                                                    <td>Boost revenue</td>
                                                </tr>
                                            </tbody>
                                         </table>
                                    </CardBody>
                                </Card>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={6}>
                                <Card profile style={{minHeight: "140px", minWidth: "150px", margin: "0 0 30px"}}>
                                    <CardBody>
                                        <h3 className={classes.cardTitle}>Amazon</h3>
                                        <table className={classes.channelsTable} style={{textAlign: "left", marginTop: "20px"}}>
                                            <thead>
                                                <tr>
                                                    <th width="35%">Export</th>
                                                    <th width="45%">Description</th>
                                                    <th>Result</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className={classes.textTheme}><strong>Product<br/>feed</strong></td>
                                                    <td>Show &amp; sell products to people on Amazon</td>
                                                    <td>Boost revenue</td>
                                                </tr>
                                            </tbody>
                                         </table>
                                    </CardBody>
                                </Card>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={6}>
                                <Card profile style={{minHeight: "207px", minWidth: "150px", margin: "0 0 30px"}}>
                                    <CardBody>
                                        <h3 className={classes.cardTitle}>eBay</h3>
                                        <table className={classes.channelsTable} style={{textAlign: "left", marginTop: "20px"}}>
                                            <thead>
                                                <tr>
                                                    <th width="35%">Export</th>
                                                    <th width="45%">Description</th>
                                                    <th>Result</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className={classes.textTheme}><strong>Product<br/>feed</strong></td>
                                                    <td>Show &amp; sell products to people on eBay</td>
                                                    <td>Boost revenue</td>
                                                </tr>
                                            </tbody>
                                         </table>
                                    </CardBody>
                                </Card>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={6}>
                                <Card profile style={{minHeight: "207px", minWidth: "150px", margin: "0 0 30px"}}>
                                    <CardBody>
                                        <h3 className={classes.cardTitle}>criteo</h3>
                                        <table className={classes.channelsTable} style={{textAlign: "left", marginTop: "20px"}}>
                                            <thead>
                                                <tr>
                                                    <th width="35%">Export</th>
                                                    <th width="45%">Description</th>
                                                    <th>Result</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className={classes.textTheme}><strong>Product<br/>feed</strong></td>
                                                    <td>Sell products online</td>
                                                    <td>Boost revenue</td>
                                                </tr>
                                            </tbody>
                                         </table>
                                    </CardBody>
                                </Card>
                            </GridItem>
                        </GridContainer>
                    </GridItem>
                </GridContainer>
                
                
                {/*
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('home.secFeeds.header.channel')}</TableCell>
                            <TableCell>{t('home.secFeeds.header.export')}</TableCell>
                            <TableCell>{t('home.secFeeds.header.description')}</TableCell>
                            <TableCell>{t('home.secFeeds.header.result')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {TableData(t)}
                    </TableBody>
                </Table>
                */}
                
            </div>
        </div>
    )
}

// eslint-disable-next-line
function TableData(t) {
    const data = {
        google: [
            "merchant",
            "promotions",
            "localInventory",
            "productRatings",
            "dynamicRemarketing",
            "page",
            "sitemap",
            "manufacturer"
        ],
        facebook: [
            "dynamicAds",
            "pageShop",
            "marketplace"
        ],
        instagram: ["shopping"],
        amazon: ["product"],
        ebay: ["product"],
        criteo: ["product"]
    }

    return Object.keys(data).map((channel) => data[channel].map((feed, index) => (
        <TableRow>
            {index === 0 && (<TableCell rowSpan={data[channel].length}>{t(`home.secFeeds.${channel}.title`)}</TableCell>)}
            <TableCell>{t(`home.secFeeds.${channel}.feeds.${feed}.name`)}</TableCell>
            <TableCell>{t(`home.secFeeds.${channel}.feeds.${feed}.desc`)}</TableCell>
            <TableCell>{t(`home.secFeeds.${channel}.feeds.${feed}.result`)}</TableCell>
        </TableRow>
    )))
}