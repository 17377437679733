import day0 from "assets/img/mtt/campaign/day-0.jpg";
import day130 from "assets/img/mtt/campaign/day-1-30.jpg";
import day1 from "assets/img/mtt/campaign/day-1.jpg";
import { frontPageSectionStyle } from "assets/jss/material-kit-pro-react/views/frontPageSections/frontPageSectionStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardAvatar from "components/Card/CardAvatar.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import React from "react";
import { useTranslation } from "react-i18next";



export function Section1(props) {
    const { t } = useTranslation();
    const classes = frontPageSectionStyle()
        return (
            <div className={`${classes.section} ${classes.bgGray}`}>
                <div className={classes.container}>
                    
                    <h2 className={`${classes.title2} ${classes.mt0}`} style={{padding: "40px 0", fontSize: "3.25rem", color: "#00ADA8"}}>{t('b2c.sec1.title')}</h2>
                
                    <GridContainer justify="space-evenly">
                        
                        <GridItem xs={12} sm={6} md={4} style={{marginBottom: "20px"}}>
                           <Card profile style={{minHeight: "275px", minWidth: "150px"}}>
                            <CardAvatar className={classes.cardAvatarIcon} profile>
                             <img src={day0} alt="Save Time"/>
                            </CardAvatar>
                            <CardBody>
                                <h3 className={classes.cardTitle} style={{textAlign: "center", fontSize: "1.5rem"}} dangerouslySetInnerHTML={{__html: t('b2c.sec1.col1.title')}}></h3>
                                <p>{t('b2c.sec1.col1.desc')}</p>
                            </CardBody>
                           </Card> 
                        </GridItem>
                        
                        <GridItem xs={12} sm={6} md={4} style={{marginBottom: "20px"}}>
                           <Card profile style={{minHeight: "275px", minWidth: "150px"}}>
                            <CardAvatar className={classes.cardAvatarIcon} profile>
                                <img src={day1} alt="Reach Audience"/>
                            </CardAvatar>
                            <CardBody>
                                <h3 className={classes.cardTitle} style={{textAlign: "center", fontSize: "1.5rem"}} dangerouslySetInnerHTML={{__html: t('b2c.sec1.col2.title')}}></h3>
                                <p>{t('b2c.sec1.col2.desc')}</p>
                            </CardBody>
                           </Card> 
                        </GridItem>
                        
                        <GridItem xs={12} sm={6} md={4} style={{marginBottom: "20px"}}>
                           <Card profile style={{minHeight: "275px", minWidth: "150px"}}>
                            <CardAvatar className={classes.cardAvatarIcon} profile>
                            <img src={day130} alt="Reduce Cost"/>
                            </CardAvatar>
                            <CardBody>
                                <h3 className={classes.cardTitle} style={{textAlign: "center", fontSize: "1.5rem"}} dangerouslySetInnerHTML={{__html: t('b2c.sec1.col3.title')}}></h3>
                                <p>{t('b2c.sec1.col3.desc')}</p>
                            </CardBody>
                           </Card> 
                        </GridItem>
                        
                    </GridContainer>
                </div>
            </div>
        );
}