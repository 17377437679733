import {
    container,
    section,
    mlAuto,
    title,
    description
} from "assets/jss/material-kit-pro-react.jsx";
  
import customCheckboxRadioSwitch from "assets/jss/material-kit-pro-react/customCheckboxRadioSwitchStyle.jsx";
import footerStyle from "assets/jss/material-kit-pro-react/views/componentsSections/footerStyle.jsx";
import rotatingCards from "assets/jss/material-kit-pro-react/rotatingCards.jsx";
  
const loginPageStyle = {
    ...rotatingCards,
    ...customCheckboxRadioSwitch,
    ...footerStyle,
    mlAuto,
    description,
    title: {
        ...title,
        color: "#555",
        fontSize: "0.9rem",
        fontWeight: "500",
        marginTop: "10px",
        marginBottom: "0"
    },
    textCenter: {textAlign:"center"},
    section: {
      ...section,
      position: "relative",
      "& $container": {
        zIndex: "2",
        position: "relative"
      },
      "& $title": {
        color: "#FFFFFF"
      },
      "& $description": {
        color: "rgba(255, 255, 255, 0.76)"
      },
      "& $infoArea": {}
    },
    contacts: {
      padding: "80px 0",
      marginTop:"100px",
      marginBottom:"100px"
    },
    infoArea: {
      padding: "0",
      margin: "0",
      "& svg,& .fab,& .fas,& .far,& .fal,& .material-icons": {
        color: "#fff"
      },
      "& h4": {
        marginTop: "20px",
        color: "#fff"
      }
    },
    card1: {
      marginTop: "30px",
      //minHeight: "600px",
    },
    cardHeader1: {
        textAlign: "center",
        background: "#00ada8",
        color: "#fff"
    },
    cardHeader2: {
        textAlign: "center",
        background: "#00ada8",
        color: "#fff"
    },
    cardTitle: {
        ...title,
        marginBottom: "0.75rem !important",
        marginTop: "10px",
        color: "#FFFFFF",
        textDecoration: "none",
        textAlign: "center !important",
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
      },
    justifyContentBetween: {
      WebkitBoxPack: "justify !important",
      MsFlexPack: "justify !important",
      justifyContent: "space-between !important"
    },
    pullRight: {
      float: "right"
    },
    autoMargin: {
        marginLeft: "auto",
        marginRight: "auto"
    },
    container: {
        ...container,
        zIndex: "2",
        position: "relative",
        paddingTop: "20vh",
        color: "#777"
      },
      pageHeader: {
        minHeight: "100vh",
        height: "auto",
        display: "inherit",
        position: "relative",
        margin: "0",
        padding: "0",
        border: "0",
        alignItems: "center",
        "&:before": {
          background: "rgba(0, 0, 0, 0)"
        },
        
        "&:before,&:after": {
          position: "absolute",
          zIndex: "1",
          width: "100%",
          height: "100%",
          display: "block",
          left: "0",
          top: "0",
          content: '""'
        }
      },
      cardSignup: {
        borderRadius: "6px",
        boxShadow:
          "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);",
        marginBottom: "100px",
        padding: "40px 0px"
      },
      inputAdornment: {
        marginRight: "18px",
        position: "relative"
      },
      inputAdornmentIcon: {
        color: "#00ada8"
      },
      form: {
        margin: "0",
        height: "100%"
      },
      dangerIcon: {
        marginBottom: "-5px"
      },
      regLink: {
        padding: "0"
      },
      passReset: {
        color: "#777777",
        display: "block",
        width: "220px",
        marginLeft: "auto",
        marginRight: "auto",
        padding: "5px 0 25px",
        "&:hover": {
          color: "#222222"
        }
      },
      btnWrap: {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto"
      },
      buttonRegNow: {
        fontSize: "1.5rem",
        marginTop: "20px",
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        background: "#00ada8",
        color: "#fff",
        "& svg": {
            width: "25px",
            height: "25px",
            marginLeft: "10px"
        }
      },
      buttonSignIn: {
        fontSize: "1.5rem",
        marginTop: "20px",
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        background: "#00ada8",
        color: "#fff",
        "& svg": {
            width: "25px",
            height: "25px",
            marginLeft: "10px"
        }
      },
      regTitle: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', 
        fontSize: "1.7rem", 
        fontWeight: "600",
        color: "#555",
        marginTop: "50px"
      },
      regText: {
        margin: "30px 0"
      },
      signInBtn: {
        background: "#00ada8",
        color: "#fff",
        marginLeft: "auto",
        marginRight: "auto"
      },
      regBtn: {
        background: "#00ada8",
        color: "#fff",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "15px"
      },
      googleSignInBtn: {
        margin: "40px auto"
      },
      checkRoot: {
        padding: "4px 14px"
      }
  };
  
  export default loginPageStyle;