//import UserServiceInterface from './UserServiceInterface'
import ApiService from './ApiService'

export default class ProjectService extends ApiService {

    async getAuditlog(projectId, page = 0, pageSize = 5) {
        return this.client.get(`/projects/${projectId}/auditlog?page=${page}&size=${pageSize}`)
    }

    async getGoogleAdsRecommendations(projectId, size = 10, page = 0) {
        return this.client.get(`/projects/${projectId}/gads/recommendations?size=${size}&page=${page}`)
    }

    async getCatalogues(projectId, size = 10, page = 0) {
        return this.client.get(`/projects/${projectId}/catalogues?size=${size}&page=${page}`)
    }

    async createCatalogue(projectId, catalogueName) {
        return this.client.post("/projects/"+projectId+"/catalogues", {
            name: catalogueName,
        })
    }

    async linkAdWordsAccount(projectId, customerId) {
        return this.client.get(`/projects/${projectId}/adwordsAccounts/${customerId}/sendInvitation`)
    }
    async getAdWordsAccounts(projectId) {
        return this.client.get(`/projects/${projectId}/adwordsAccounts`)
    }
    
    async getProject(projectId) {
        return this.client.get("/projects/"+projectId)
    }

    async getApiKey(projectId) {
        return this.client.get("/projects/"+projectId+"/apiKey")
    }

    async setApiKey(projectId, domain) {
        return this.client.put("/projects/"+projectId+"/apiKey", {
            domain: domain
        })
    }

    async getSubscription(projectId) {
        return this.client.get("/projects/"+projectId+"/subscription")
    }

    async setBillingAccount(projectId, name, vatNumber, address, city, zip, country) {
        return this.client.put("/projects/"+projectId+"/billingAccount", {
            name: name,
            vatNumber: vatNumber,
            address: address,
            city: city,
            zip: zip,
            country: country
        })
    }
}
