/* eslint-disable */
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react components for routing our app without refresh
import { Link } from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Icon from "@material-ui/core/Icon";


// @material-ui/icons
import Apps from "@material-ui/icons/Apps";
import People from "@material-ui/icons/People";
import Tune from "@material-ui/icons/Tune";
import LineStyle from "@material-ui/icons/LineStyle";
import Layers from "@material-ui/icons/Layers";
// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";

import { withTranslation } from "react-i18next"
import headerLinksStyle from "assets/jss/material-kit-pro-react/components/headerLinksStyle.jsx";
import { connect } from "react-redux";

class HeaderLinks extends React.Component {

    constructor(props) {
        super(props)
    }
    
    easeInOutQuad = (t, b, c, d) => {
        t /= d / 2;
        if (t < 1) return c / 2 * t * t + b;
        t--;
        return -c / 2 * (t * (t - 2) - 1) + b;
    };

    smoothScroll = (e, target) => {
        if (window.location.pathname === "/sections") {
            var isMobile = navigator.userAgent.match(
                /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
            );
            if (isMobile) {
                // if we are on mobile device the scroll into view will be managed by the browser
            } else {
                e.preventDefault();
                var targetScroll = document.getElementById(target);
                scrollGo(document.documentElement, targetScroll.offsetTop, 1250);
            }
        }
    };
    scrollGo = (element, to, duration) => {
        var start = element.scrollTop,
            change = to - start,
            currentTime = 0,
            increment = 20;

        var animateScroll = function () {
            currentTime += increment;
            var val = easeInOutQuad(currentTime, start, change, duration);
            element.scrollTop = val;
            if (currentTime < duration) {
                setTimeout(animateScroll, increment);
            }
        };
        animateScroll();
    };
    
    
    
    render = () => {
        var onClickSections = {};

        const { classes, dropdownHoverColor, t } = this.props;

        const listItems = []
        if (this.props.user) {
            listItems.push(
                <ListItem key="dashboard" className={classes.listItem} aria-label="dashboard" >
                    <Link to="/admin/dashboard" className={classes.dropdownLink} style={{color:"#00ada8"}}>
                        {t('menu.dashboard')}
                    </Link>
                </ListItem>
            )
        }
        else {
            listItems.push(
                <ListItem key="login" className={classes.listItem}>
                    <Link to="/login" className={classes.dropdownLink} aria-label="Sign In" style={{color:"#00ada8"}}>
                        {t('menu.signin')}
                    </Link>
                </ListItem>
            )
        }

        return (
            <List className={classes.list + " " + classes.mlAuto}>
                {listItems}
            </List>
        );
    }
}

HeaderLinks.defaultProps = {
    hoverColor: "primary"
};

HeaderLinks.propTypes = {
    dropdownHoverColor: PropTypes.oneOf([
        "dark",
        "primary",
        "info",
        "success",
        "warning",
        "danger",
        "rose"
    ])
};

const mapStateToProps = state => {
    return {
        user: state.user
    }
}

export default connect(mapStateToProps)(withStyles(headerLinksStyle)(withTranslation()(HeaderLinks)));
