import {
    container,
    mlAuto,
    mrAuto,
    title
  } from "assets/jss/material-kit-pro-react.jsx";
  import footerStyle from "assets/jss/material-kit-pro-react/views/componentsSections/footerStyle.jsx";
  const footerContentStyle = {
      ...footerStyle,
    container,
    mlAuto,
    mrAuto,
    title,
    footerItem: {
        width: "auto",
        maxWidth: "240px",
        height: "40px"
    },
    left: {
        float: "left",
        maxWidth: "300px",
        display: "inline-block"
    },
    icon: {
        top: "3px",
        width: "18px",
        height: "18px",
        position: "relative"
      },
      copyRight: {
        padding: "15px 0px"
      },
      footer: {
        "& ul li": {
          display: "inline-block"
        },
        "& h4, & h5": {
          color: "#00ada8",
          textDecoration: "none"
        },
        "& ul:not($socialButtons) li a": {
          color: "inherit",
          padding: "0.9375rem",
          fontWeight: "500",
          fontSize: "12px",
          textTransform: "uppercase",
          borderRadius: "3px",
          textDecoration: "none",
          position: "relative",
          display: "block"
        },
        "& small": {
          fontSize: "75%",
          color: "#777"
        },
        "& $pullCenter": {
          display: "inline-block",
          float: "none"
        }
      },
      aClass: {
        textDecoration: "none",
        backgroundColor: "transparent"
      },
      block: {
        color: "inherit",
        padding: "0.9375rem",
        fontWeight: "500",
        fontSize: "12px",
        textTransform: "uppercase",
        borderRadius: "3px",
        textDecoration: "none",
        position: "relative",
        display: "block"
      },
      colTitle: {
        textTransform: "uppercase",
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: "600",
        marginTop: "20px",
        textAlign: "left",
        color: "rgba(255,255,255,0.9)"
      },
      footerBrand: {
        opacity: "0.9",
        height: "50px",
        padding: "0",
        fontSize: "28px",
        lineHeight: "40px",
        marginLeft: "0",
        marginTop: "15px",
        color: "#fff",
        textDecoration: "none",
        fontWeight: 700,
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
      },
      footerBrand2: {
        opacity: "0.9",
        height: "25px",
        padding: "0",
        fontSize: "28px",
        lineHeight: "25px",
        marginLeft: "0",
        marginTop: "0",
        color: "#fff",
        textDecoration: "none",
        fontWeight: 700,
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
      },
      poweredBy: {
        margin: "25px 0 0 5px",
        fontSize: "0.7rem",
        textAlign: "left",
        color: "rgba(255,255,255,0.9)"
      },
      about: {
        margin: "25px 35px 0 5px",
        fontSize: "0.8rem",
        lineHeight: "1.3",
        textAlign: "left",
        color: "rgba(255,255,255,0.9)"
      },
      pullCenter: {
        display: "inline-block",
        float: "none"
      },
      rightLinks: {
        float: "none",
        "& ul": {
          marginBottom: 0,
          float: "left",
          marginTop: "10px",
          marginLeft: "0",
          padding: "0",
          listStyle: "none",
          "& li": {
            display: "inline-block"
          },
          "& a": {
            display: "block"
          }
        },
        "& i": {
          fontSize: "20px"
        }
      },
      linksVertical: {
        "& li": {
          display: "block !important",
          marginLeft: "-5px",
          marginRight: "-5px",
          "& a": {
            padding: "5px !important"
          }
        }
      },
      socialFeed: {
        "& p": {
          display: "table-cell",
          verticalAlign: "top",
          overflow: "hidden",
          paddingBottom: "10px",
          maxWidth: 300
        },
        "& i": {
          fontSize: "20px",
          display: "table-cell",
          paddingRight: "10px"
        }
      },
      img: {
        width: "20%",
        marginRight: "5%",
        marginBottom: "5%",
        float: "left"
      },
      list: {
        marginBottom: "0",
        padding: "0",
        marginTop: "0"
      },
      inlineBlock: {
        display: "inline-block",
        padding: "0px",
        width: "auto"
      },
      mttLogo: {
          maxHeight: "60px",
          maxWidth: "auto"
      },
      localAgencies: {
          
          "& a": {
            fontWeight: "300",
              paddingTop: "0",
              paddingBottom: "0"
          }
      },
      leftCol: {
        textAlign: "left"
      },
      innerCol: {
        
      },
      rightCol: {
        paddingLeft: "40px"
      },
      circleIcon: {
        border: "2px solid rgba(255,255,255,0.7)",
        borderRadius: "50%",
        width: "45px",
        height: "45px",
        display: "table-cell",
        verticalAlign: "middle",
        textAlign: "center",
        paddingTop: "2px"
      },
      colList: {
        margin: "25px 0 0",
        padding: "0",
        "& li": {
            padding: "0",
            "& a": {
                padding: "0",
                textTransform: "none",
                fontSize: "0.8rem",
                color: "rgba(255,255,255,0.9)" 
            }
        }
      },
      copy: {
        margin: "25px 0 0 5px",
        fontSize: "0.7rem",
        textAlign: "left",
        color: "rgba(255,255,255,0.9)"
      },
      socialIcons: {
        "& li a": {
            padding: "10px 20px 0 0"
        },
        "& li a span i": {
            color: "rgba(255,255,255,0.9)"
        },
        "& li a:hover span": {
            borderColor: "#fff"
        },
        "& li a:hover span i": {
            color: "#fff"
        }
      },
      langForm: {
        textAlign: "left",
        marginTop: "40px",
          minHeight: "60px",
          
        "& svg, & div": {
          color: "white",
          
          "&:focus": {
            backgroundColor: "transparent"
          }
        }},
        langswitchControl: {
          textAlign: "left",
          color: "white",
          fontWeight: "700",
          maxWidth: "176px",
          marginTop: "40px",
          "&:before": {

          }
        
      },
      langSelect: {
        textAlign: "left",
        "&:focus": {
          backgroundColor: "transparent!important"
        },
        "&:after": {
          borderBottom: "1px solid #EEE",
        },
        "&:hover": {
          borderBottom: "1px solid #EEE",
          "&:before": {
            borderBottom: "1px solid #EEE!important",
          }
        },
        "&:before": {
          borderBottom: "1px solid white"
        },
        "& div": {
          minWidth: "100px"
        }
        
      },
      langItem: {
        width: "176px",
        marginRight: "17px"
      },
      linkWhite: {
        color: "rgba(255,255,255,0.9)",
        "&:hover": {
           color: "#fff" 
        }
      }
  }
  export default footerContentStyle;