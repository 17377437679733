import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// core components
import Header from "components/Header/Header.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Footer from "components/Footer/Footer.jsx";
import FooterContent from "components/Footer/FooterContent.jsx";


import styles from "assets/jss/material-kit-pro-react/views/privacyPageStyle.jsx"

class PrivacyPage extends React.Component {
    componentDidMount () {
        document.title = "GPlugin - Privacy Policy";
        window.scrollTo(0,0);
        document.body.scrollTop = 0;
    }
    render() {
        const { classes } = this.props;
        return (
            <div>
                
                <Header brand="MTT Digital" links={<HeaderLinks dropdownHoverColor="info" />} fixed color="white" changeColorOnScroll={{ height: 200, color: "white" }} />

                <div className={classes.subPageHeader}>
                    <div className={classes.container}>
                        <h2 className={classes.subPageHeaderTitle}>
                            Privacy Policy
                        </h2>
                    </div>
                </div>
                
                <div className={classNames(classes.main, classes.mainRaised)} style={{padding:"40px 0"}}>
                    <div className={classes.container}>


                        <ExpansionPanel className={classes.expansionPanel} defaultExpanded>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography className={classes.expansionHeading}>
                                    Privacy policy
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Typography className={classes.expansionBody}>
                                    Our privacy policy tells you what personal data (PD) and non-personal data (NPD) GPlugin may collect from you, how we collect it, how we protect it, how we may share it, and how you can access, change, or delete it. Our privacy policy also explains certain legal rights that you have with respect to your personal data. If at any time you have questions about our practices or any of your rights described below, please write to us by email at privacy@gplugin.uk or by mail to MTT Digital Kft., Széll Kálmán tér 11., 1024 Budapest, Hungary Attn: Privacy.  This Privacy Policy applies to all websites, domains, software, offerings, information portals, and registries (“site”, “sites” and/or “services”) maintained by GPlugin.         
                                    <br/><br/>
                                    <small><em>Last Updated: February 26, 2019</em></small>
                                </Typography>
                            </ExpansionPanelDetails>
                        </ExpansionPanel> 
                        
                        
                            
                        <ExpansionPanel className={classes.expansionPanel}>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography className={classes.expansionHeading}>
                                    Definitions
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Typography className={classes.expansionBody}>
                                    <ul>
                                        <li>‘Non-personal data’ (NPD) is information that is in no way personally identifiable.</li>
                                        <li>‘Personal data’ (PD) means any information relating to an identified or identifiable natural person (‘data subject’); an identifiable natural person is one who can be identified, directly or indirectly, by reference to an identifier such as a name, an identification number, location data, an online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of that natural person. Personal Data is in many ways the same as Personally Identifiable Information (PII). However, Personal Data is broader in scope and covers more data.</li>
                                        <li>A “visitor” is someone who merely browses our website. A “user” is someone who has registered with us to use or buy our services and products.</li>
                                    </ul>
                                </Typography>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>    
                        
                        
                         
                        <ExpansionPanel className={classes.expansionPanel}>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography className={classes.expansionHeading}>
                                    Information we collect
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Typography className={classes.expansionBody}>
                                    Generally, you control the amount and type of information that you provide to us when using our website.
                                </Typography>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>            
                        
                        
                        
                        <ExpansionPanel className={classes.expansionPanel}>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography className={classes.expansionHeading}>
                                    Our legal basis for processing personal data (EEA individuals only)
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Typography className={classes.expansionBody}>
                                    If you are a visitor from the European Economic Area (EEA), our legal basis for processing your personal data will depend on the personal data concerned and the specific context in which we collect it.
                                    <br/><br/>
                                    However, we will normally collect personal data from you only where we have your consent to do so, where we need the personal data to perform a contract with you, or where the processing is in our legitimate interests and not overridden by your data protection interests or fundamental rights and freedoms.  In some cases, we may also have a legal obligation to process personal data about you to protect your vital interests or those of another person.
                                    <br/><br/>
                                    If we collect and use your personal data in reliance on our legitimate interests (or those of any third party), this interest will normally be to operate our website and communicate with you as necessary to provide our products and services to you and for our legitimate commercial interest. These legitimate interests include, for example, responding to your queries, improving our platform, undertaking marketing, or detecting or preventing illegal activities.  We may have other legitimate interests and if appropriate we will make clear to you at the relevant time what those legitimate interests are.
                                    <br/><br/>
                                    If you have questions about or need further information concerning the legal basis on which we collect and use your personal data, please contact us using the contact details provided above.
                                </Typography>
                            </ExpansionPanelDetails>
                        </ExpansionPanel> 
                        
                        
                        
                        <ExpansionPanel className={classes.expansionPanel}>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography className={classes.expansionHeading}>
                                    We collect your personal data in the following ways
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Typography className={classes.expansionBody}>
                                    <strong>AUTOMATIC INFORMATION</strong><br/><br/>
                                    We automatically receive information from your web browser or mobile device. This information may include the name of the website from which you entered our website, if any, as well as the name of the website you’ll visit when you leave our website, your Internet service provider’s name, your web browser type, the type of mobile device, your computer operating system, and data about your browsing activity when using our website. We use all this information to analyze trends among our users to help improve our website.<br/><br/>
                                    <strong>WHEN ENTERING AND USING OUR WEBSITE</strong><br/><br/>
                                    When you enter and use our website you accept cookies, some of these cookies may contain your Personal Data. A cookie is a small piece of data or a text file that is downloaded to your computer or mobile device when you access certain websites. Cookies may contain text that can be read by the web server that delivered the cookie to you. The text contained in the cookie generally consists of a sequence of letters and numbers that uniquely identifies your computer or mobile device; it may contain other information as well.
                                </Typography>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>  
                        
                        
                        
                        <ExpansionPanel className={classes.expansionPanel}>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography className={classes.expansionHeading}>
                                    We may use cookies for
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Typography className={classes.expansionBody}>
                                    <ul>
                                        <li>
                                            Identifying the areas of our website that you have visited.
                                        </li>
                                        <li>
                                            Personalizing content that you see on our website.
                                        </li>
                                        <li>
                                             Our website analytics.
                                        </li>
                                        <li>
                                            Remarketing our products or services to you.
                                        </li>
                                        <li>
                                            Remembering your preferences, settings, and login details.
                                        </li>
                                        <li>
                                            Targeted advertising and serving ads relevant to your interests.
                                        </li>
                                        <li>
                                             Affiliate marketing.
                                        </li>
                                        <li>
                                            Allowing you to post comments.
                                        </li>
                                        <li>
                                           Allowing you to share content with social networks. 
                                        </li>
                                    </ul>
                                </Typography>
                            </ExpansionPanelDetails>
                        </ExpansionPanel> 
                        
                        
                        <ExpansionPanel className={classes.expansionPanel}>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography className={classes.expansionHeading}>
                                    Do not track settings
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Typography className={classes.expansionBody}>
                                     You may modify and control how and when cookies are set through your browser settings. If you choose to reject cookies, certain of the functions and conveniences of our website may not work properly, and you may be unable to use some of our services that require registration in order to participate, or you will have to re-register each time you visit our site.
                                </Typography>
                            </ExpansionPanelDetails>
                        </ExpansionPanel> 
                        
                        
                        
                        <ExpansionPanel className={classes.expansionPanel}>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography className={classes.expansionHeading}>
                                    At user registration or when using products or services
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Typography className={classes.expansionBody}>
                                    When you are registering for our product or free tools (i.e. Graders, Keyword Tools, etc), during the purchasing process, or when using our website and services, we may collect some or all of the following information: your first and last name, email address, physical address, company name, credit card or other payment information, phone number, user name, password, and other information listed.
                                </Typography>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        
                        
                        
                        <ExpansionPanel className={classes.expansionPanel}>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography className={classes.expansionHeading}>
                                    Web beacons
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Typography className={classes.expansionBody}>
                                    We may also use a technology called web beacons and other technologies, such as pixels and ad tags, to collect general information about your use of our website and your use of special promotions or newsletters. The information we collect by web beacons allows us to statistically monitor the number of people who open our emails. Web beacons also help us to understand the behavior of our customers, members, and visitors.
                                </Typography>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        
                        
                        
                        <ExpansionPanel className={classes.expansionPanel}>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography className={classes.expansionHeading}>
                                    Location information
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Typography className={classes.expansionBody}>
                                    When you use a location-enabled device, we may collect and process information about your actual location, like GPS signals sent by a mobile device.  We also may use various locations to determine location, such as sensor data from your device that may, for example, provide information on nearby Wi-Fi access points and cell towers.  
                                </Typography>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        
                        
                        
                        <ExpansionPanel className={classes.expansionPanel}>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography className={classes.expansionHeading}>
                                    New technologies
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Typography className={classes.expansionBody}>
                                    As new technologies emerge, we may be able to improve our services or provide you with new ones, which means that we may create new ways to collect information on the site and our services.  If we offer a new service or new features to our existing site, for example, these changes may result in our collecting new information in order to improve your user experience.   
                                </Typography>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        
                        
                        
                        <ExpansionPanel className={classes.expansionPanel}>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography className={classes.expansionHeading}>
                                    Google Analytics privacy policy
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Typography className={classes.expansionBody}>
                                    Our website uses Google Analytics to collect information about the use of our website. Google Analytics collects information from users such as age, gender, interests, demographics, how often they visit our website, what pages they visit, and what other websites they have used before coming to our website. We use the information we get from Google Analytics to analyze traffic, remarket our products and services to users, improve our marketing, advertising, and to improve our website. We have enabled Google Analytics advertising features such as remarketing with Google Analytics, Google Display Network Impression Reporting, and Google Analytics Demographics and Interest Reporting. Google Analytics collects only the IP address assigned to you on the date you visit our website, not your name or other identifying information. We do not combine the information collected using Google Analytics with Personal Data. Although Google Analytics plants a permanent cookie on your web browser to identify you as a unique user the next time you visit our website, the cookie cannot be used by anyone but Google. Google also uses specific identifiers to help collect information about the use of our website.
                                    <br/><br/>
                                    For more information on how Google collects and processes your data, visit <a href="https://www.google.com/policies/privacy/partners" target="_blank" rel="noopener noreferrer"> https://www.google.com/policies/privacy/partners/</a>
                                    <br/>
                                    You can prevent Google Analytics from using your information by opting out at this link: <a href="https://tools.google.com/dlpage/gaoptout" target="_blank" rel="noopener noreferrer"> https://tools.google.com/dlpage/gaoptout </a>
                                </Typography>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        
                        
                        
                        <ExpansionPanel className={classes.expansionPanel}>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography className={classes.expansionHeading}>
                                    How your infromation is used
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Typography className={classes.expansionBody}>
                                    We use your information for our legitimate interests including: 
                                    <br/><br/>
                                    <ul>
                                        <li>
                                            Provide our products and services you have requested or purchased from us.
                                        </li>
                                        <li>
                                            Respond to your questions and provide you support.
                                        </li>
                                        <li>
                                            Personalize and customize our content.
                                        </li>
                                        <li>
                                            Make improvements to our website.
                                        </li>
                                        <li>
                                            Contact you with updates to our product.
                                        </li>
                                        <li>
                                            Resolve problems and disputes.
                                        </li>
                                        <li>
                                            Contact you with marketing and advertising that we believe may be of interest to you.
                                        </li>
                                    </ul>
                                </Typography>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        
                        
                        
                        <ExpansionPanel className={classes.expansionPanel}>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography className={classes.expansionHeading}>
                                    Communications and emails
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Typography className={classes.expansionBody}>
                                    From time to time GPlugin (and our partners, affiliates, and resellers) may email you electronic newsletters, announcements, surveys or other information. If you prefer not to receive any or all of these communications, you may opt out by following the unsubscribe directions provided within the electronic newsletters and announcements. While you may opt-out of receiving promotional emails from GPlugin, you may not opt out of receiving product related transactional emails from us relating to your active GPlugin account.  
                                </Typography>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        
                        
                        
                        <ExpansionPanel className={classes.expansionPanel}>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography className={classes.expansionHeading}>
                                    Sharing information with affiliates and other third parties
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Typography className={classes.expansionBody}>
                                    We do not sell or rent your Personal Data to third parties for marketing purposes.  We do, however, engage third party companies and individuals to perform services on our behalf.  The types of services and providers may include:   
                                    <br/><br/>
                                    <ul>
                                        <li>
                                            Order Management, Billing, and Payment processors (Adobe Sign, Zuora, Vantiv).
                                        </li>
                                        <li>
                                            Analytics services (Google Analytics, Geckoboards, Hotjar, SpyFu, Profitwell, Tableau or MaxMind).
                                        </li>
                                        <li>
                                            Advertising networks (Google, Facebook, Bing, or Amazon).
                                        </li>
                                        <li>
                                            Data management services (Marketo, Salesforce, or Mintigo).
                                        </li>
                                        <li>
                                            Communication services (GoToMeeting, TimeTrade, Snapengage).
                                        </li>
                                        <li>
                                            Product management services (FullStory, AWS, AppCues, PaperTrail, or Mixpanel).
                                        </li>
                                        <li>
                                            Customer success services (Gainsight or GetFeedback).
                                        </li>
                                        <li>
                                            Auditors (MFA - Moody, Famiglietti & Andronico)
                                        </li>
                                    </ul>
                                    These third parties have access to your information so that they may perform these tasks on our behalf and they are prohibited by us from using or disclosing your information for any purpose other than to provide this assistance, except to the extent required by law. We may permit our service providers to use aggregate information which does not identify you or de-identified data for other purposes.  
                                </Typography>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        
                        
                        
                        <ExpansionPanel className={classes.expansionPanel}>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography className={classes.expansionHeading}>
                                    Quality control
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Typography className={classes.expansionBody}>
                                    We may monitor or record any of your telephone conversations, emails, or chats with us for quality control purposes, for purposes of training our employees, and for our own protection.  
                                </Typography>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        
                        
                        
                        <ExpansionPanel className={classes.expansionPanel}>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography className={classes.expansionHeading}>
                                    Legally required releases of information
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Typography className={classes.expansionBody}>
                                    We may be legally required to disclose your Personal Data if such disclosure is  
                                    <br/><br/> 
                                    <ul>
                                        <li>
                                            required by subpoena, law, or other legal process.
                                        </li>
                                        <li>
                                            necessary to assist law enforcement officials or government enforcement agencies.
                                        </li>
                                        <li>
                                            necessary to investigate violations of or otherwise enforce our Legal Terms.
                                        </li>
                                        <li>
                                            necessary to protect us from legal action or claims from third parties, including you and/or other users or members.
                                        </li>
                                        <li>
                                            necessary to protect the legal rights, personal/real property, or personal safety of our company, users, employees, and affiliates.
                                        </li>
                                    </ul> 
                                </Typography>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        
                        
                        
                        <ExpansionPanel className={classes.expansionPanel}>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography className={classes.expansionHeading}>
                                    Disclosures to successors
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Typography className={classes.expansionBody}>
                                    If our business is sold or merges in whole or in part with another business that would become responsible for providing GPlugin to you, we retain the right to transfer your Personal Data to the new business. The new business would retain the right to use your Personal Data according to the terms of this privacy policy as well as to any changes to this privacy policy as instituted by the new business.    
                                    <br/><br/> 
                                    We also retain the right to transfer your Personal Data if our company files for bankruptcy and some or all of our assets are sold to another individual or business.
                                </Typography>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        
                        
                        
                        <ExpansionPanel className={classes.expansionPanel}>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography className={classes.expansionHeading}>
                                    Retaining your personal data
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Typography className={classes.expansionBody}>
                                    We retain Personal Data that you provide us as long as we consider it potentially useful in contacting you about our product or our other services, or as needed to comply with our legal obligations, resolve disputes and enforce our agreements, and then we securely delete the data. We will delete this data from the servers at an earlier date if you so request, as described in "Reviewing, Correcting, and Removing Your Personal Data" below.  
                                </Typography>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        
                        
                        
                        <ExpansionPanel className={classes.expansionPanel}>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography className={classes.expansionHeading}>
                                    Reviewing, correcting, and removing your personal data
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Typography className={classes.expansionBody}>
                                    Upon request GPlugin will provide you with information about whether we hold any of your Personal Data. If you provide us with your Personal Data, you have the following rights with respect to that information:   
                                    <br/><br/>
                                    <ul>
                                        <li>
                                            To review the user information that you have supplied to us
                                        </li>
                                        <li>
                                            To request that we correct any errors, outdated information, or omissions in user information that you have supplied to us
                                        </li>
                                        <li>
                                            To request that your user information not be used to contact you
                                        </li>
                                        <li>
                                            To request that your user information be removed from any solicitation list that we use
                                        </li>
                                        <li>
                                            To request that your user information be deleted from our records
                                        </li>
                                        <li>
                                            To opt out of being solicited by GPlugin or third parties
                                        </li>
                                    </ul>
                                    To exercise any of these rights, please contact us at privacy@gplugin.uk or by mail to MTT Digital Kft., Széll Kálmán tér 11., 1024 Budapest, Hungary, Attn: Privacy. We will respond to your request to change, correct, or delete your information within a reasonable timeframe and notify you of the action we have taken.   
                                </Typography>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        
                        
                        
                        <ExpansionPanel className={classes.expansionPanel}>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography className={classes.expansionHeading}>
                                    Links to other websites
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Typography className={classes.expansionBody}>
                                    Our website may contain links to other websites. These websites are not under our control and are not subject to our privacy policy. These websites will likely have their own privacy policies. We have no responsibility for these websites and we provide links to these websites solely for your convenience. You acknowledge that your use of and access to these websites are solely at your risk. It is your responsibility to check the privacy policies of these websites to see how they treat your Personal Data.    
                                </Typography>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        
                        
                        
                        <ExpansionPanel className={classes.expansionPanel}>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography className={classes.expansionHeading}>
                                    Protecting children’s privacy
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Typography className={classes.expansionBody}>
                                    Even though our website is not designed for use by anyone under the age of 16, we realize that a child under the age of 16 may attempt to access our website. We do not knowingly collect Personal Data from children under the age of 16. If you are a parent or guardian and believe that your child is using our website, please contact us at dpo@GPlugin.com. Before we remove any information, we may ask for proof of identification to prevent malicious removal of account information. If we discover that a child is accessing our website, we will delete his/her information within 30 days. You acknowledge that we do not verify the age of our users nor do we have any liability to do so.   
                                </Typography>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        
                        
                        
                        <ExpansionPanel className={classes.expansionPanel}>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography className={classes.expansionHeading}>
                                    Our email policy
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Typography className={classes.expansionBody}>
                                    You can always opt out of receiving further email correspondence from us. We will not sell, rent, or trade your email address to any unaffiliated third party without your permission except in the sale or transfer of our business, or if our company files for bankruptcy.  
                                </Typography>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        
                        
                        
                        <ExpansionPanel className={classes.expansionPanel}>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography className={classes.expansionHeading}>
                                    Our security policy
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Typography className={classes.expansionBody}>
                                    We have built our website and products using industry-standard security measures and authentication tools to protect the security of your Personal Data.  We and the third parties who provide services for us, also maintain technical and physical safeguards to protect your Personal Data. We also periodically monitor our system for possible vulnerabilities and attacks, consistent with industry standards.  When we collect your credit card information through our website, we will encrypt it before it travels over the Internet using industry-standard technology for conducting secure online transactions. Unfortunately, we cannot guarantee against the loss or misuse of your Personal Data or secure data transmission over the Internet because of its nature.We strongly urge you to protect any password you may have for our website and to not share it with anyone. You should always log out of our website when you finish using it, especially if you are sharing or using a computer in a public place.  Please note that emails, instant messaging, and similar means of communication with other GPlugin website users, if any, are not encrypted, so you should not communicate any confidential information through these means.   
                                </Typography>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        
                        
                        
                        <ExpansionPanel className={classes.expansionPanel}>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography className={classes.expansionHeading}>
                                    Use of your credit card
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Typography className={classes.expansionBody}>
                                    You may have to provide a credit card to buy products and services from our website. We use third-party billing services. We use our commercially reasonable efforts to make sure your credit card number is kept strictly confidential by using only third-party billing services that use industry-standard encryption technology to protect your credit card number from unauthorized use. However, you understand and agree that we are in no way responsible for any misuse of your credit card number.  
                                </Typography>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        
                        
                        
                        <ExpansionPanel className={classes.expansionPanel}>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography className={classes.expansionHeading}>
                                    Data transfers and privacy shield
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Typography className={classes.expansionBody}>
                                    Your personal information may be transferred to, and processed in, countries other than the country in which you are resident. These countries may have data protection laws that are different from the laws of your country (and, in some cases, may not be as protective).  
                                    <br/><br/>
                                    We have taken appropriate safeguards to require that your personal information will remain protected in accordance with this privacy notice.   
                                    <br/><br/>
                                    GPlugin has applied to participate in the EU-U.S. Privacy Shield Framework and the Swiss – U.S. Privacy Shield Framework as set forth by the U.S. Department of Commerce regarding the collection, use, and retention of personal data transferred from the European Union (EU) and Switzerland to the United States, respectively. GPlugin has certified to the Department of Commerce that it adheres to the Privacy Shield Principles, and commits to comply with the Privacy Shield Principles as they apply to the personal data of EU and Swiss residents. If there is any conflict between the terms in this privacy notice and the Privacy Shield Principles, the Privacy Shield Principles shall govern. To learn more about the Privacy Shield program, and to view our certification, please visit <a href="https://www.privacyshield.gov/." target="_blank" rel="noopener noreferrer"> https://www.privacyshield.gov/.</a>
                                    <br/><br/>
                                    GPlugin is responsible for the processing of personal data it receives under the Privacy Shield Framework and subsequently transfers to a third party acting as an agent on its behalf. GPlugin complies with the Privacy Shield Principles for all onward transfers of personal data from the EU and Switzerland, including the onward transfer liability provisions. 
                                    <br/><br/>
                                    GPlugin commits to resolve complaints about our collection or use of your personal data in accordance with the Privacy Shield Principles. Individuals in the EU or Switzerland may submit queries related to the processing of personal data under the Privacy Shield framework by contacting us directly at privacy@gplugin.uk,  or by mail to MTT Digital Kft., Széll Kálmán tér 11., 1024 Budapest, Hungary, Attn: Privacyl.   
                                    <br/><br/>
                                    GPlugin has further committed to cooperate with the European data protection authorities (the “DPAs”) and/or the Swiss Federal Data Protection and Information Commissioner (the “Commissioner”), as applicable. If we have not been able to successfully resolve your query or complaint and you are based in the EEA or Switzerland, you may raise your query or complaint directly to the DPA or Commissioner. The services of the DPAs and Commissioner are provided at no cost to you. You can find details of the relevant DPA at http://ec.europa.eu/justice/data-protection/article-29/structure/data-protection-authorities/index_en.htm. GPlugin will comply with any advice given by the DPAs and/or Commissioner, and take necessary actions to remediate any non-compliance with the Privacy Shield Principles.  
                                    <br/><br/>
                                    If your inquiry still has not been satisfactorily resolved, you may have other recourse mechanisms such as invoking binding arbitration by the Privacy Shield Panel.   
                                </Typography>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        
                        
                        
                        <ExpansionPanel className={classes.expansionPanel}>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography className={classes.expansionHeading}>
                                    Changes to our privacy policy
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Typography className={classes.expansionBody}>
                                    We reserve the right to change this privacy policy at any time. If our company decides to change this privacy policy, we will post those changes on our website so that our visitors, users and customers are always aware of what information we collect, use, and disclose. If at any time we decide to disclose or use your Personal Data in a method different from that specified at the time it was collected, we will provide advance notice by email (sent to the email address on file in your account). Otherwise we will use and disclose our users’ and customers’ Personal Data in agreement with the privacy policy in effect when the information was collected. In all cases, your continued use of our website, services, and products after any change to this privacy policy will constitute your acceptance of such change.  
                                </Typography>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        
                        
                        
                        <ExpansionPanel className={classes.expansionPanel}>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography className={classes.expansionHeading}>
                                    Questions about our privacy policy
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Typography className={classes.expansionBody}>
                                    If you have any questions about our privacy policy, please contact us using the information at the top of this privacy policy.
                                </Typography>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        
                        
                        
                    </div>
                </div>
                
                <Footer theme="white" content={<FooterContent />} />
                
            </div>  
        )
    }
} export default withStyles(styles)(PrivacyPage)