// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import backg from "assets/img/mtt/plexus_1.jpg";
import loginPageStyle from "assets/jss/material-kit-pro-react/views/loginPageStyle.jsx";
import GoogleSignInButton from "components/CustomButtons/GoogleSignInButton.jsx";
import Footer from "components/Footer/Footer.jsx";
import FooterContent from "components/Footer/FooterContent.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import SlimHeader from "components/Header/SlimHeader";
import SlimHeaderLinks from "components/Header/SlimHeaderLinks";
import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { requestSignIn } from "redux/actions";
import { Helmet } from "react-helmet";






class LoginPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            user: props.user,
            activeRotate1: "",
            activeRotate2: "",
            checked: [],
            errors: {
                login: false,
                register: {
                    email: false,
                    password: false,
                    domain: false,
                    terms: false,
                    privacyPolicy: false
                }
            },
            isHidden: true
        };
        this.mt = window.mt
    }
    
    toggleHidden() {
        this.setState({
            isHidden: !this.state.isHidden
        })
    }

    componentDidMount() {
        const { classes } = this.props;
        var rotatingCards = document.getElementsByClassName(classes.cardRotate);
        for (let i = 0; i < rotatingCards.length; i++) {
            var rotatingCard = rotatingCards[i];
            var rotatingWrapper = rotatingCard.parentElement;
            var cardWidth = rotatingCard.parentElement.offsetWidth;
            var cardHeight = rotatingCard.children[0].children[0].offsetHeight;
            rotatingWrapper.style.height = cardHeight + 20 + "px";
            rotatingWrapper.style["margin-bottom"] = 80 + "px";
            var cardFront = rotatingCard.getElementsByClassName(classes.front)[0];
            cardFront.style.height = cardHeight + 25 + "px";
            cardFront.style.width = cardWidth + "px";

        }
        document.title = "FeedStudio - Sign In";
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
    }

    handleToggle(value) {
        const { checked } = this.state;
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        this.setState({
            checked: newChecked
        });
        
    }
    
    componentWillReceiveProps(nextProps) {
        if(nextProps.user) {
            if(window.mt) {
                window.mt('send', 'pageview', {email: nextProps.user.username, firstname: nextProps.user.firstName, lastname: nextProps.user.lastName, reg_website: true})
            }
            
            if(window.gtag) {
                window.gtag('config', 'UA-42995494-10', {
                    'page_title' : 'Registration - website',
                    'page_path': '/registration/website/'
                    });
            }
            
            nextProps.history.push('/admin/dashboard')
        }
    }
    render() {
        const { classes, t } = this.props;

        return (
            <div>
                <Helmet>
                    <title>{t("home.meta.title")}</title>
                    <meta name="description" content={t("home.meta.desc.short")} />
                    <link rel="canonical" href="https://feed.studio" />
                    <meta name="robots" content="index,follow" />
                    <meta itemprop="name" content={t("home.meta.title")}/>
                    <meta itemprop="description" content={t("home.meta.desc.short")}/>
                    <meta itemprop="image" content="http://www.example.com/image.jpg"/>
                    <meta name="twitter:card" content="http://example.com/image.jpg"/>
                    <meta name="twitter:site" content="@twitter"/>
                    <meta name="twitter:title" content={t("home.meta.title")}/>
                    <meta name="twitter:description" content={t("home.meta.desc.long")}/>
                    <meta name="twitter:creator" content="@twitter"/>
                    <meta name="twitter:image:src" content="http://www.example.com/image.jpg"/>
                    <meta property="og:title" content={t("home.meta.title")}/>
                    <meta property="og:type" content="website" />
                    <meta property="og:url" content="https://feed.studio" />
                    <meta property="og:image" content="http://example.com/image.jpg" />
                    <meta property="og:description" content={t("home.meta.desc.short")} />
                    <meta property="og:site_name" content="Feed Studio" />
                </Helmet>
                <SlimHeader brand="MTT Digital" links={<SlimHeaderLinks dropdownHoverColor="info" />} fixed color="transparent" changeColorOnScroll={{ height: 50, color: "white" }} />

                <div className={classes.pageHeader} style={{ backgroundImage: "url(" + backg + ")", backgroundSize: "cover", backgroundPosition: "top center" }}>
                    <div className={classes.container}>
                        <GridContainer>
                            <GridItem xs={12} sm={6} md={7}>
                                <div id="loginText">
                                    <h2 className={classes.regTitle}>
                                        {t('login.title')}
                                    </h2>
                                    <h5 className={classes.regText}>
                                        {t('login.subtitle')}
                                    </h5>
                                    <h5 className={classes.regText}>
                                        {t('login.subtitle2')}
                                    </h5>
                                    <GoogleSignInButton aria-label="Google login"
                                        color="white"
                                        onSignInSuccessful={(res) => this.props.requestSignIn(res.code)}>
                                        <img width="22" height="22" alt="Google" style={{marginRight:"10px"}} src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2aWV3Qm94PSIwIDAgNDggNDgiPjxkZWZzPjxwYXRoIGlkPSJhIiBkPSJNNDQuNSAyMEgyNHY4LjVoMTEuOEMzNC43IDMzLjkgMzAuMSAzNyAyNCAzN2MtNy4yIDAtMTMtNS44LTEzLTEzczUuOC0xMyAxMy0xM2MzLjEgMCA1LjkgMS4xIDguMSAyLjlsNi40LTYuNEMzNC42IDQuMSAyOS42IDIgMjQgMiAxMS44IDIgMiAxMS44IDIgMjRzOS44IDIyIDIyIDIyYzExIDAgMjEtOCAyMS0yMiAwLTEuMy0uMi0yLjctLjUtNHoiLz48L2RlZnM+PGNsaXBQYXRoIGlkPSJiIj48dXNlIHhsaW5rOmhyZWY9IiNhIiBvdmVyZmxvdz0idmlzaWJsZSIvPjwvY2xpcFBhdGg+PHBhdGggY2xpcC1wYXRoPSJ1cmwoI2IpIiBmaWxsPSIjRkJCQzA1IiBkPSJNMCAzN1YxMWwxNyAxM3oiLz48cGF0aCBjbGlwLXBhdGg9InVybCgjYikiIGZpbGw9IiNFQTQzMzUiIGQ9Ik0wIDExbDE3IDEzIDctNi4xTDQ4IDE0VjBIMHoiLz48cGF0aCBjbGlwLXBhdGg9InVybCgjYikiIGZpbGw9IiMzNEE4NTMiIGQ9Ik0wIDM3bDMwLTIzIDcuOSAxTDQ4IDB2NDhIMHoiLz48cGF0aCBjbGlwLXBhdGg9InVybCgjYikiIGZpbGw9IiM0Mjg1RjQiIGQ9Ik00OCA0OEwxNyAyNGwtNC0zIDM1LTEweiIvPjwvc3ZnPg==" />
                                        <span style={{fontSize:"15px",textTransform:"none"}}>
                                            {t('login.googleButton')}
                                        </span>
                                    </GoogleSignInButton>
                                </div>
                            </GridItem>
                        </GridContainer>
                        
                    </div>
                </div>
                
                <Footer theme="white" content={<FooterContent />} />
                
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        isUserFetching: state.isUserFetching
    }
}

const mapDispatchToProps = dispatch => {
    return {
        requestSignIn: (code) => dispatch(requestSignIn(code))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(loginPageStyle)(withTranslation()(LoginPage)))