import ImportExportFlowImg from "assets/img/sections/import-export-flow.png";
import ImportExportFlowImgMobile from "assets/img/sections/_import-export-flow.png";
import { frontPageSectionStyle } from "assets/jss/material-kit-pro-react/views/frontPageSections/frontPageSectionStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardAvatar from "components/Card/CardAvatar.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import React from "react";
import { useTranslation } from "react-i18next";



export function Section2() {
    const { t } = useTranslation();
    const classes = frontPageSectionStyle()
    return (
        <div id="benefits" className={`${classes.section}`} style={{backgroundImage:"url('/static/media/plexus_1.8c4e13f8.jpg')", backgroundPosition: "75%"}}>
            <div className={classes.container} style={{marginTop: "40px"}}>
                
                <h2 className={classes.title2} style={{padding: "40px 0", fontSize: "3.25rem", color: "#555"}}>{t('home.sec2.title')}</h2>
                
                <div style={{marginBottom: "100px", textAlign: "center", padding: "15px"}}>
                    <img src={ImportExportFlowImg} className={classes.hiddenXs} alt="Import & Export Flow" style={{width: "100%", height: "auto"}} />
                    <img src={ImportExportFlowImgMobile} className={classes.visibleXs} alt="Import & Export Flow" style={{width: "100%", height: "auto"}} />
                </div>
            
                <GridContainer justify="space-evenly" style={{paddingBottom: "50px"}}>

                    <GridItem xs={12} sm={6} md={4}>
                       <Card profile style={{minHeight: "332px", minWidth: "150px", marginTop: "0"}}>
                        <CardAvatar className={classes.cardAvatarIcon} profile style={{margin: "25px auto 0"}}>
                            <img src={require("assets/img/sections/sfm.png")} alt="..." style={{maxWidth: "105px"}} />
                        </CardAvatar>
                        <CardBody>
                            <h3 className={classes.cardTitle} style={{textAlign: "center", fontSize: "1.5rem"}} dangerouslySetInnerHTML={{__html: t('home.sec2.col1.title')}}></h3>
                            <p>{t('home.sec2.col1.desc')}</p>
                        </CardBody>
                       </Card> 
                    </GridItem>
                    
                    <GridItem xs={12} sm={6} md={4}>
                       <Card profile style={{minHeight: "332px", minWidth: "150px", marginTop: "0"}}>
                        <CardAvatar className={classes.cardAvatarIcon} profile style={{margin: "25px auto 0"}}>
                            <img src={require("assets/img/sections/formats.png")} alt="..." style={{maxWidth: "105px"}} />
                        </CardAvatar>
                        <CardBody>
                            <h3 className={classes.cardTitle} style={{textAlign: "center", fontSize: "1.5rem"}} dangerouslySetInnerHTML={{__html: t('home.sec2.col2.title')}}></h3>
                            <p>{t('home.sec2.col2.desc')}</p>
                        </CardBody>
                       </Card> 
                    </GridItem>
                    
                    <GridItem xs={12} sm={6} md={4}>
                       <Card profile style={{minHeight: "332px", minWidth: "150px", marginTop: "0"}}>
                        <CardAvatar className={classes.cardAvatarIcon} profile style={{margin: "25px auto 0"}}>
                            <img src={require("assets/img/sections/optimize.png")} alt="..." style={{maxWidth: "105px"}} />
                        </CardAvatar>
                        <CardBody>
                            <h3 className={classes.cardTitle} style={{textAlign: "center", fontSize: "1.5rem"}} dangerouslySetInnerHTML={{__html: t('home.sec2.col3.title')}}></h3>
                            <p>{t('home.sec2.col3.desc')}</p>
                        </CardBody>
                       </Card> 
                    </GridItem>
                    
                    <GridItem xs={12} sm={6} md={4}>
                       <Card profile style={{minHeight: "220px", minWidth: "150px", marginTop: "0"}}>
                        <CardAvatar className={classes.cardAvatarIcon} profile style={{margin: "25px auto 0"}}>
                            <img src={require("assets/img/sections/history.png")} alt="..." style={{maxWidth: "105px"}} />
                        </CardAvatar>
                        <CardBody>
                            <h3 className={classes.cardTitle} style={{textAlign: "center", fontSize: "1.5rem"}} dangerouslySetInnerHTML={{__html: t('home.sec2.col4.title')}}></h3>
                            <p>{t('home.sec2.col4.desc')}</p>
                        </CardBody>
                       </Card> 
                    </GridItem>

                    <GridItem xs={12} sm={6} md={4}>
                       <Card profile style={{minHeight: "220px", minWidth: "150px", marginTop: "0"}}>
                        <CardAvatar className={classes.cardAvatarIcon} profile style={{margin: "25px auto 0"}}>
                            <img src={require("assets/img/sections/seo.png")} alt="..." style={{maxWidth: "105px"}} />
                        </CardAvatar>
                        <CardBody>
                            <h3 className={classes.cardTitle} style={{textAlign: "center", fontSize: "1.5rem"}} dangerouslySetInnerHTML={{__html: t('home.sec2.col5.title')}}></h3>
                            <p>{t('home.sec2.col5.desc')}</p>
                        </CardBody>
                       </Card> 
                    </GridItem>

                    <GridItem xs={12} sm={6} md={4}>
                       <Card profile style={{minHeight: "220px", minWidth: "150px", marginTop: "0"}}>
                        <CardAvatar className={classes.cardAvatarIcon} profile style={{margin: "25px auto 0"}}>
                            <img src={require("assets/img/sections/ms.png")} alt="..." style={{maxWidth: "105px"}} />
                        </CardAvatar>
                        <CardBody>
                            <h3 className={classes.cardTitle} style={{textAlign: "center", fontSize: "1.5rem"}} dangerouslySetInnerHTML={{__html: t('home.sec2.col6.title')}}></h3>
                            <p>{t('home.sec2.col6.desc')}</p>
                        </CardBody>
                       </Card> 
                    </GridItem>
                    
                </GridContainer>
            </div>
        </div>
    )
}