import { List, ListItem, ListItemText, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@material-ui/core";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputBase from "@material-ui/core/InputBase";
import { frontPageSectionStyle } from "assets/jss/material-kit-pro-react/views/frontPageSections/frontPageSectionStyle.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ApiService from "services/ApiService";
import Button from "components/CustomButtons/Button";

export function Section2(props) {
    const { t } = useTranslation();
    const classes = frontPageSectionStyle()
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [feedUrl, setFeedUrl] = useState("")
    const [feedType, setFeedType] = useState("")
    const [policyChecked, setPolicyChecked] = useState(false)
    const [marketingChecked, setMarketingChecked] = useState(false)
    const [successDialogOpen, setSuccessDialogOpen] = useState(false)
    const [errorDialogOpen, setErrorDialogOpen] = useState(false)

    async function handleSubmit(e) {
        e.preventDefault()
        
        if (policyChecked) {
            const apiService = new ApiService()

            try {
                await apiService.earlyBirdSubscription(name, email, phone, feedType, feedUrl)
                setSuccessDialogOpen(true)
            } catch (e) {
                setErrorDialogOpen(true)
            }

            if (window.mt) {
                let nameParts = name.split(" ")

                window.mt('send', 'pageview', {
                    email: email,
                    lastname: nameParts.shift(),
                    firstname: nameParts.join(" "),
                    newsletter: marketingChecked ? 1 : 0
                })
            }
        }
    }

    return (
        <div className={`${classes.section} ${classes.root}`} style={{ paddingBottom: "70px" }}>
            <Dialog
                open={successDialogOpen}
                onClose={() => setSuccessDialogOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Success"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        You have successfully requested an erly bird access! Please wait for us to contact you at the given address.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setSuccessDialogOpen(false)} color="primary" autoFocus>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={errorDialogOpen}
                onClose={() => setErrorDialogOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Error"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        There was an unexpected error while requesting you access. Please try again later.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setErrorDialogOpen(false)} color="primary" autoFocus>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
            <div className={classes.container} style={{ marginTop: "40px" }}>

                <h2 className={classes.title2} style={{ padding: "40px 0", fontSize: "3.25rem", color: "#00ADA8" }}>{t('b2c.sec2.title')}</h2>

                <GridContainer justify="space-evenly">
                    <GridItem sm={12} md={6}>
                        <p className={`${classes.description} ${classes.mb2}`}>{t('earlybirdreg.form.infoList.desc1')}</p>
                        <p className={classes.description}>{t('earlybirdreg.form.infoList.desc2')}</p>
                        <List className={classes.bulletpointList}>
                            <ListItem>
                                <ListItemText>{t('earlybirdreg.form.infoList.item1')}</ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemText>{t('earlybirdreg.form.infoList.item2')}</ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemText>{t('earlybirdreg.form.infoList.item3')}</ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemText>{t('earlybirdreg.form.infoList.item4')}</ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemText>{t('earlybirdreg.form.infoList.item5')}</ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemText>{t('earlybirdreg.form.infoList.item6')}</ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemText>{t('earlybirdreg.form.infoList.item7')}</ListItemText>
                            </ListItem>
                        </List>
                    </GridItem>
                    <GridItem sm={12} md={6} lg={5}>
                        <form action="" method="post" onSubmit={(e) => handleSubmit(e)}>
                            <div>
                                <InputBase
                                    placeholder={t('b2c.sec2.form.name')}
                                    inputProps={{ 'aria-label': t('b2c.sec2.form.name') }}
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                            <div>
                                <InputBase
                                    required={true}
                                    placeholder={t('b2c.sec2.form.email')}
                                    inputProps={{ 'aria-label': t('b2c.sec2.form.email') }}
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />

                            </div>
                            <div>
                                <InputBase
                                    placeholder={t('b2c.sec2.form.phone')}
                                    inputProps={{ 'aria-label': t('b2c.sec2.form.phone') }}
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                />

                            </div>
                            <div>
                                <InputBase
                                    placeholder={t('b2c.sec2.form.feedType')}
                                    inputProps={{ 'aria-label': t('b2c.sec2.form.feedType') }}
                                    value={feedType}
                                    onChange={(e) => setFeedType(e.target.value)}
                                />
                            </div>
                            <div>
                                <InputBase
                                    placeholder={t('b2c.sec2.form.feedUrl')}
                                    inputProps={{ 'aria-label': t('b2c.sec2.form.feedUrl') }}
                                    value={feedUrl}
                                    onChange={(e) => setFeedUrl(e.target.value)}
                                />
                            </div>
                            <div>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            required={true}
                                            checked={policyChecked}
                                            onChange={() => setPolicyChecked(!policyChecked)}
                                            name="Policy checkbox"
                                            color="primary"
                                        />
                                    }
                                    label={t('b2c.sec2.form.policyLabel')}
                                />
                            </div>
                            <div>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={marketingChecked}
                                            onChange={() => setMarketingChecked(!marketingChecked)}
                                            name="Marketing checkbox"
                                            color="primary"
                                        />
                                    }
                                    label={t('b2c.sec2.form.marketingLabel')}
                                />
                            </div>
                            <div className={classes.textCenter}>
                                <Button round type="submit">
                                    <h5>
                                        {t('b2c.sec2.form.button')}
                                    </h5>
                                </Button>
                            </div>
                        </form>
                    </GridItem>
                </GridContainer>
            </div>
        </div>
    )
}