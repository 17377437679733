import React from "react";

import UserService from "services/UserService";
import qs from 'qs'

import { connect } from "react-redux"

class LoginRedirectPage extends React.Component {

    constructor(props) {
        super(props)

        var query = qs.parse(props.location.search)
        this.state = {
            redirectUrl: null,
            complete: query.complete?query.complete:0,
            token: query.token?query.token:null,
        }
    }

    componentDidMount() {
        var userService = new UserService(this.props.user)
        userService.getAuthUrl('https://gplugin.uk/login/redirect?complete=1')
            .then((res) => {
                this.setState({redirectUrl: res.data.data.url})
            })
    }
    
    render() {
        if(this.state.token != null) {
            console.log(JSON.stringify({token: this.state.token}))
            window.opener.postMessage(JSON.stringify({token: this.state.token}), "*")
            window.close()
            return ''
        }
        if(this.state.redirectUrl !== null) {
            window.location = this.state.redirectUrl
        }

        return (<span>You will be redirected in a second.{this.state.redirectUrl !== null?<a href={this.state.redirectUrl}>Please click here if you are not redirected automatically!</a>:''}</span>)
    }
}

const mapStateToProps = state => {
    return {
        user: state.user
    }
}
export default connect(mapStateToProps)(LoginRedirectPage)