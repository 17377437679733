import _ from "lodash"

function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c === 'x' ? r : ((r & 0x3) | 0x8);
        return v.toString(16);
    });
}

const countries = [
    {
        id: 0,
        name: "Hungary"
    },
    {
        id: 1,
        name: "United Kingdom"
    },
    {
        id: 2,
        name: "Finland"
    },
    {
        id: 3,
        name: "Sweden"
    }
]

export default {
    users: [
        {
            id: 0,
            username: "test@mtt.digital",
            password: "test",
            token: "df2qvbwptl7gh59uuyybt",
            defaultProject: 0,
            firstLogin: false
        }
    ],
    projects: [
        {
            id: 0,
            name: "User Project: test@mtt.digital",
            apiKey: {
                id: 0,
                domain: "test1.mtt.digital",
                key: "0000000000"
            },
            subscription: {
                balance: 100,
                billingAccount: {
                    name: "MTT Digital Kft.",
                    vatNumber: "25503160-2-41",
                    address: "Széll Kálmán tér 11.",
                    city: "Budapest",
                    zip: 1024,
                    country: countries[0].id
                },
                invoices: [
                    {
                        id: 0,
                        date: "2018-01-01",
                        type: "Balance Top-up",
                        amount: 2000
                    },
                    {
                        id: 1,
                        date: "2018-01-01",
                        type: "Subscription Fee",
                        amount: 300
                    },
                    {
                        id: 2,
                        date: "2018-01-01",
                        type: "Balance Top-up",
                        amount: 5000
                    }
                ]
            },
            hasAdsAccount: true
        }
    ],
    adsAccounts: [
        {
            id: 9876543210,
            name: "Test account",
            shouldError: false
        }
    ],
    catalogues: [
        {
            id: 0,
            name: "Test catalogue"
        }
    ],
    products: _.times(10, (i) => {

        let price = Math.floor(Math.random() * (10000 - 1000 + 1) + 1000)
        let salePercent = Math.floor(Math.random() * (25 - 0 + 1) + 0)

        return {
            id: uuidv4(),
            external_id: i,
            name: "Test product",
            description: "Lorem ipsum dolor sit amet",
            price: price,
            sale_price: price * salePercent / 100,
            url: "example.com",
            imageUrl: ""
        }
    }),
    revisions: [
        {
            "revisionId": 0,
            "createdDate": "2019-01-01T12:00:00.000+0000",
            "feedName": "feeds/Feed/Feed_2019_09_02_11_27_07.xml",
            "synchronStatus": "FINISHED_ADWORDS_UPLOAD",
            "revisionType": "AUTOMATED",
            "productId": "eca559d9-f236-427d-a600-13e7bbd63b6f"
        },
        {
            "revisionId": 1,
            "createdDate": "2019-01-01T13:30:00.000+0000",
            "feedName": "feeds/Feed/Feed_2019_09_02_11_27_08.xml",
            "synchronStatus": "FINISHED_ADWORDS_UPLOAD",
            "revisionType": "AUTOMATED",
            "productId": "eca559d9-f236-427d-a600-13e7bbd63b6f"
        }
    ],
    exports: [
        {
            "id": 0,
            "name": "Shopping Feed",
            "writer": "DEFAULT_MERCHANT"
        },
        {
            "id": 1,
            "name": "Page Feed",
            "writer": "DEFAULT_PAGEFEED"
        },
        {
            "id": 2,
            "name": "DSA",
            "writer": "DEFAULT_CUSTOM_DYNAMIC_REMARKETING"
        }
    ],
    exportTypes: [
        {
            "id": 0,
            "name": "Shopping Feed"
        },
        {
            "id": 1,
            "name": "Page Feed"
        },
        {
            "id": 2,
            "name": "DSA"
        }
    ],
    exportPreview:
        `<?xml version="1.0" encoding="UTF-8"?>
<rss xmlns:c="http://base.google.com/cns/1.0" xmlns:g="http://base.google.com/ns/1.0" version="2.0">
    <channel>
        <item>
            <g:id>16777677</g:id>
            <g:title>HO Chico Fürdőszobai ülőke</g:title>
            <g:description>Króm váz, fehér műbőr ülőhuzat.</g:description>
            <g:short_description>HO Chico Fürdőszobai ülőke</g:short_description>
            <c:product_type1>Bútorok</c:product_type1>
            <c:product_type2>Fürdőszoba</c:product_type2>
            <c:product_type3>Fürdőszobai kiegészítők</c:product_type3>
            <c:product_type4>Egyéb kiegészítők</c:product_type4>
            <g:link>
                https://www.kika.hu/shop/hu/kikahu/butor/furdoszoba/furdoszobai-kiegeszitok/egyeb-kiegeszitok/ho*chico*furdoszobai-uloke*-16777677
            </g:link>
            <g:image_link>https://products.kika-cdn.com/full/hu/3388204.jpg/</g:image_link>
            <g:condition>new</g:condition>
            <g:availability>in stock</g:availability>
            <c:filiale>20.0</c:filiale>
            <g:price>6790.00 HUF</g:price>
            <g:pattern/>
            <g:gtin/>
            <g:mpn/>
            <g:brand/>
            <g:shipping>
                <g:price>.00 HUF</g:price>
            </g:shipping>
            <g:shipping_weight>2.700 kg</g:shipping_weight>
            <g:color>fehér</g:color>
            <g:material>textil-bőr</g:material>
            <c:customercardproduct>false</c:customercardproduct>
            <c:always_sale>false</c:always_sale>
            <c:sales_price>false</c:sales_price>
            <c:order_online>true</c:order_online>
            <c:height>48</c:height>
            <c:depth/>
            <c:width/>
            <c:price_group>Preisklasse 1</c:price_group>
        </item>
    </channel>
</rss>`,
    sources: [
        {
            id: 0,
            name: "Product feed"
        }
    ],
    countries: countries
}