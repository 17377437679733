import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { withTranslation } from "react-i18next"
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import Paper from '@material-ui/core/Paper';
// core components
import Button from "components/CustomButtons/Button.jsx";
// icon
import YesIcon from "@material-ui/icons/Check";
import NoIcon from "@material-ui/icons/Clear";
import styles from "assets/jss/material-kit-pro-react/views/pricingPageSections/pricingPlanStyle.jsx";
class PricingTable extends React.Component {
    render(){
        const {classes, t, signupLink} = this.props;
        return (
            <div id="pricingplan" className={`${classes.section}`} style={{background:"#fff", padding: "40px 0"}}>
                <div className={classes.container}>
                    <h2 className={classes.title2} style={{fontSize: "3.25rem", color: "#555"}}>{t('pricing.header.title')} <br/> <small>{t('pricing.header.sub')} </small></h2>
                    <Paper className={classes.paper}>
                        <Table className={`${classes.table}, ${classes.pricingTable}`}>
                            <colgroup>
                                <col />
                                <col className={classes.boxShadow} />
                                <col className={classes.boxShadow} />
                                <col className={classes.boxShadow} />
                            </colgroup>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.textLeft} style={{background:"#fff"}}> {t('pricing.plugin.col1.header')} </TableCell>
                                    <TableCell style={{background:"#fff",color:"#777"}}> {t('pricing.plugin.col2.header')} </TableCell>
                                    <TableCell style={{background:"#f3f3f3",color:"#00ada8"}}>{t('pricing.plugin.col3.header')} </TableCell>
                                    <TableCell style={{background:"#00ada8",color:"#fff"}}> {t('pricing.plugin.col4.header')} </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                 <TableRow className={classes.row}>
                                    <TableCell className={classes.textLeft}> {t('pricing.plugin.col1.row1')} </TableCell>
                                    <TableCell> <YesIcon className={classes.yesIcon} /> </TableCell> 
                                    <TableCell> <YesIcon className={classes.yesIcon} /> </TableCell> 
                                    <TableCell> <YesIcon className={classes.yesIcon} /> </TableCell>
                                </TableRow>
                                <TableRow className={classes.row}> 
                                    <TableCell className={classes.textLeft}> {t('pricing.plugin.col1.row2')} </TableCell> 
                                    <TableCell> <YesIcon className={classes.yesIcon} /> </TableCell> 
                                    <TableCell> <YesIcon className={classes.yesIcon} /> </TableCell> 
                                    <TableCell> <YesIcon className={classes.yesIcon} /> </TableCell> 
                                </TableRow>
                                <TableRow className={classes.row}> 
                                    <TableCell className={classes.textLeft}>  {t('pricing.plugin.col1.row3')} </TableCell> 
                                    <TableCell> <YesIcon className={classes.yesIcon} /> </TableCell> 
                                    <TableCell> <YesIcon className={classes.yesIcon} /> </TableCell> 
                                    <TableCell> <YesIcon className={classes.yesIcon} /> </TableCell> 
                                </TableRow>
                                <TableRow className={classes.row}> 
                                    <TableCell className={classes.textLeft}>  {t('pricing.plugin.col1.row4')} </TableCell> 
                                    <TableCell> <YesIcon className={classes.yesIcon} /> </TableCell> 
                                    <TableCell> <YesIcon className={classes.yesIcon} /> </TableCell> 
                                    <TableCell> <YesIcon className={classes.yesIcon} /> </TableCell> 
                                </TableRow>
                                <TableRow className={classes.row}> 
                                    <TableCell className={classes.textLeft}>  {t('pricing.plugin.col1.row5')} </TableCell> 
                                    <TableCell> <YesIcon className={classes.yesIcon} /> </TableCell> 
                                    <TableCell> <YesIcon className={classes.yesIcon} /> </TableCell> 
                                    <TableCell> <YesIcon className={classes.yesIcon} /> </TableCell> 
                                </TableRow>    
                                <TableRow className={classes.row}>
                                    <TableCell className={classes.textLeft}> {t('pricing.plugin.col1.row6')} </TableCell>
                                    <TableCell> <YesIcon className={classes.yesIcon} /> </TableCell>
                                    <TableCell> <YesIcon className={classes.yesIcon} /> </TableCell>
                                    <TableCell> <YesIcon className={classes.yesIcon} /> </TableCell> 
                                </TableRow>
                                <TableRow className={classes.row}> 
                                    <TableCell className={classes.textLeft}> {t('pricing.plugin.col1.row7')} </TableCell> 
                                    <TableCell> <YesIcon className={classes.yesIcon} /> </TableCell> 
                                    <TableCell> <YesIcon className={classes.yesIcon} /> </TableCell> 
                                    <TableCell> <YesIcon className={classes.yesIcon} /> </TableCell> 
                                </TableRow>
                                <TableRow className={classes.row}> 
                                    <TableCell className={classes.textLeft}> {t('pricing.plugin.col1.row8')} </TableCell> 
                                    <TableCell> <YesIcon className={classes.yesIcon} /> </TableCell> 
                                    <TableCell> <YesIcon className={classes.yesIcon} /> </TableCell> 
                                    <TableCell> <YesIcon className={classes.yesIcon} /> </TableCell> 
                                </TableRow>
                                <TableRow className={classes.row}> 
                                    <TableCell className={classes.textLeft}> {t('pricing.plugin.col1.row9')} </TableCell> 
                                    <TableCell> <NoIcon/></TableCell> 
                                    <TableCell> <YesIcon className={classes.yesIcon} /> </TableCell> 
                                    <TableCell> <YesIcon className={classes.yesIcon} /> </TableCell> 
                                </TableRow>  
                                <TableRow className={classes.row}> 
                                    <TableCell className={classes.textLeft}>  {t('pricing.plugin.col1.row10')} </TableCell> 
                                    <TableCell> <YesIcon className={classes.yesIcon} /> </TableCell> 
                                    <TableCell> <YesIcon className={classes.yesIcon} /> </TableCell> 
                                    <TableCell><YesIcon className={classes.yesIcon} /> </TableCell> 
                                </TableRow> 
                                <TableRow className={classes.row}> 
                                    <TableCell className={classes.textLeft}> {t('pricing.plugin.col1.row11')} </TableCell> 
                                    <TableCell> <YesIcon className={classes.yesIcon} />  </TableCell> 
                                    <TableCell> <YesIcon className={classes.yesIcon} />  </TableCell> 
                                    <TableCell> <YesIcon className={classes.yesIcon} /> </TableCell> 
                                </TableRow> 
                                <TableRow className={classes.row}> 
                                    <TableCell className={classes.textLeft}> {t('pricing.plugin.col1.row12')} </TableCell> 
                                    <TableCell> <NoIcon/> </TableCell> 
                                    <TableCell> <YesIcon className={classes.yesIcon} />  </TableCell> 
                                    <TableCell> <YesIcon className={classes.yesIcon} /> </TableCell> 
                                </TableRow>                           
                            </TableBody> 
                            <TableFooter>                                 
                                <TableRow className={classes.row}> 
                                    <TableCell className={classes.textLeft} > {t('pricing.plugin.col1.row13')} </TableCell> 
                                    <TableCell> <NoIcon/> </TableCell> 
                                    <TableCell className={classes.textCenter}> {t('pricing.plugin.col3.row13')} </TableCell>  
                                    <TableCell className={classes.textCenter}> {t('pricing.plugin.col4.row13')} </TableCell> 
                                </TableRow> 
                                <TableRow className={classes.row}> 
                                    <TableCell className={classes.textLeft}> {t('pricing.plugin.col1.row14')} </TableCell> 
                                    <TableCell className={classes.textCenter}> {t('pricing.plugin.col2.row14')} </TableCell> 
                                    <TableCell className={classes.textCenter}> {t('pricing.plugin.col3.row14')} </TableCell> 
                                    <TableCell className={classes.textCenter}> {t('pricing.plugin.col4.row14')} </TableCell> 
                                </TableRow> 
                                <TableRow className={classes.row}> 
                                    <TableCell className={classes.textLeft}> {t('pricing.plugin.col1.row15')} </TableCell> 
                                    <TableCell className={classes.textCenter}> {t('pricing.plugin.col2.row15')} </TableCell> 
                                    <TableCell className={classes.textCenter}> {t('pricing.plugin.col3.row15')} </TableCell> 
                                    <TableCell className={classes.textCenter}> {t('pricing.plugin.col4.row15')} </TableCell> 
                                </TableRow>       
                                
                                <TableRow className={classes.row}> 
                                    <TableCell> </TableCell> 
                                    <TableCell> 
                                        <Button round href={signupLink} style={{background:"#f3f3f3",color:"#777",border:"1px solid #ddd"}} className={classes.Btn}> 
                                        {t('pricing.plugin.col2.cta')}
                                        </Button> 
                                    </TableCell> 
                                    <TableCell> 
                                        <Button round href={signupLink} style={{background:"#777",color:"#fff"}} className={classes.Btn}> 
                                        {t('pricing.plugin.col3.cta')}
                                        </Button> 
                                    </TableCell> 
                                    <TableCell> 
                                         <Button round href={signupLink} style={{background:"#00ada8",color:"#fff"}} className={classes.Btn}> 
                                         {t('pricing.plugin.col4.cta')}
                                        </Button> 
                                    </TableCell>
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </Paper>
                </div>
            </div>
        );
    }
}
export default withStyles(styles)(withTranslation()(PricingTable));