import {
    container,
    title,
    main,
    mainRaised
} from "assets/jss/material-kit-pro-react.jsx";
  
import footerStyle from "assets/jss/material-kit-pro-react/views/componentsSections/footerStyle.jsx";

const pricingPageStyle = {
    ...container,
    ...footerStyle,
    ...main,
    ...mainRaised,
    title: {
        ...title,
        color: "#777",
    },
    title1: {
        ...title,
        color: "#777",
        fontSize: "4.2rem",
        paddingTop: "40px"
    },
    brand: {
        color: "#00ada8",
        textAlign: "center",
        "& h1": {
            fontFamily: "Poppins, Helevetica, sans-serif",
            fontSize: "4.2rem",
            fontWeight: "600",
            display: "inline-block",
            position: "relative"
        }
    },
    subPageHeader: {
        marginTop: "90px",
        background: "#00ada8",
        color: "#fff",
        "& > div": {
            padding: "40px 15px"
        }
    },
    subPageHeaderTitle: {
        margin: "0",
        padding: "0",
        fontFamily: `"Poppins", "Times New Roman", serif`,
        fontWeight: "600",
        fontSize: "2.3rem"
    },
    expansionPanel: {
        margin: "20px 0",
        boxShadow: "none",
        "&:before": {
            height: "0"
        }
    },
    expansionHeading: {
        fontSize: "1.3rem",
        color: "#00ada8"
    },
    expansionBody: {
        fontSize: "1rem",
        color: "#777"
    }
};

export default pricingPageStyle;