import Axios from 'axios'
import MockAdapter from 'axios-mock-adapter'
import MockData from './MockData'

var mock = new MockAdapter(Axios, { delayResponse: 1000 });

const getUserPattern = /\/users\/(\d+)$/
const getProjectsPattern = /\/users\/(\d+)\/projects$/
const getAdsAccounstPattern = /\/projects\/(\d+)\/adwordsAccounts$/
const getCataloguesPattern = /projects\/(\d+)\/catalogues/
const getProductsPattern = /catalogues\/(\d+)\/products[^/]/
const getProductPattern = /catalogues\/(\d+)\/products\/([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})$/
const getproductRevisionsPattern = /catalogues\/(\d+)\/products\/([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})\/revisions/
const getExportsPattern = /catalogues\/\d+\/exports$/
const getExportPreviewPattern = /catalogues\/\d+\/exports\/\d+\/preview\/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/
const getExportTypesPattern = /\/export-types$/
const getRevisionsPattern = /sources\/(\d+)\/revisions/
const getSourcesPattern = /catalogues\/\d+\/sources$/

mock.onGet(getUserPattern).reply((config) => {
    let userId = parseInt(config.url.match(getUserPattern)[1])

    let userData = MockData.users.find(function (user) {
        return user.id === userId
    });

    if (!userData) {
        return [404, {
            status: 404,
            data: {
                message: "User not found"
            }
        }];
    }


    return [200, {
        status: 200,
        data: {
            id: userData.id,
            username: userData.username,
            defaultProject: userData.defaultProject
        }
    }];
});

mock.onPost("/users/googleAuth").reply((config) => {
    let userData = MockData.users[0];

    let response = {
        status: 200,
        data: {
            id: userData.id,
            username: userData.username,
            token: userData.token,
            defaultProject: userData.defaultProject
        }
    }
    return [200, response];

})

mock.onGet(getProjectsPattern).reply((config) => {
    let projects = MockData.projects.map((project) => {
        return {
            id: project.id,
            name: project.name,
            hasAdWordsAccount: project.hasAdsAccount
        }
    })


    return [200, {
        status: 200,
        data: projects
    }];
});

mock.onGet(getAdsAccounstPattern).reply((config) => {
    return [200, {
        status: 200,
        data: MockData.adsAccounts
    }];
});

mock.onGet(getCataloguesPattern).reply((config) => {
    return [200, {
        status: 200,
        data: MockData.catalogues
    }];
})

mock.onGet(getProductsPattern).reply((config) => {
    return [200, {
        status: 200,
        data: MockData.products
    }];
})

mock.onGet(getProductPattern).reply((config) => {
    let productId = config.url.match(getProductPattern)[2]
    let product = MockData.products.find((product) => {
        return product.id === productId
    })

    if(product === undefined) {
        product = MockData.products[0]
    }

    return [200, {
        status: 200,
        data: product
    }];
})

mock.onGet(getproductRevisionsPattern).reply((config) => {
    return [200, {
        status: 200,
        data: MockData.revisions
    }];
})

mock.onGet(getRevisionsPattern).reply((config) => {
    return [200, {
        status: 200,
        data: MockData.revisions
    }];
})

mock.onGet(getExportsPattern).reply((config) => {
    return [200, {
        status: 200,
        data: MockData.exports
    }];
})

mock.onPost(getExportsPattern).reply((config) => {
    let data = JSON.parse(config.data)
    MockData.exports.push({
        id: MockData.exports.length,
        name: data.name
    })

    return [200, {
        status: 200,
        data: MockData.exports
    }];
})

mock.onGet(getExportPreviewPattern).reply((config) => {
    return [200, {
        status: 200,
        data: MockData.exportPreview
    }];
})

mock.onGet(getExportTypesPattern).reply((config) => {
    return [200, {
        status: 200,
        data: MockData.exportTypes
    }];
})

mock.onGet(getSourcesPattern).reply((config) => {
    return [200, {
        status: 200,
        data: MockData.sources
    }];
})

mock.onPost(getSourcesPattern).reply((config) => {
    let data = JSON.parse(config.data)
    MockData.sources.push({
        id: MockData.sources.length,
        name: data.name
    })

    return [200, {
        status: 200,
        data: MockData.sources
    }];
})

mock.onAny().reply((config) => {
    console.log(`unhandled request in mock server: ${config.method} ${config.url}`)
    return [404]
})

export default mock