// nodejs library that concatenates classes
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import withWidth from '@material-ui/core/withWidth';
import frontpagestyle from "assets/jss/material-kit-pro-react/views/frontPageStyle.jsx";
import classNames from "classnames";
import Button from "components/CustomButtons/Button.jsx";
import Footer from "components/Footer/Footer.jsx";
import FooterContent from "components/Footer/FooterContent.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
//import { Link } from "react-router-dom";
// core components
import SlimHeader from "components/Header/SlimHeader.jsx";
import SlimHeaderLinks from "components/Header/SlimHeaderLinks.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
import PricingTable from "components/PricingTable/PricingTable";
import PropTypes from 'prop-types';
import React from "react";
import { withTranslation } from 'react-i18next';
import compose from 'recompose/compose';
import { CtaSection } from "./Section/CtaSection";
import { Section1 } from "./Section/Section1";
import { Section2 } from "./Section/Section2";
import { Section3 } from "./Section/Section3";
import { Link } from "react-router-dom";


class SlimFrontPage extends React.Component {
    state = {
        name: "",
        email: "",
        phone: "",
        feedUrl: "",
        policyChecked: false,
        marketingChecked: false,
        successDialogOpen: false,
        errorDialogOpen: false
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
    }

    render() {
        const { classes, t } = this.props;
        return (
            <div className={classes.root}>
                <SlimHeader brand="Feed.Studio"  fixed color="transparent" changeColorOnScroll={{ height: 200, color: "white" }} links={<SlimHeaderLinks dropdownHoverColor="info" />} />

                <Parallax image={require("assets/img/mtt/plexus_1.jpg")} className={classes.parallax}>
                    <div className={classes.container} style={{ marginTop: "-100px" }}>
                        <GridContainer justify="center" alignItems="center">
                            <GridItem xs={12} lg={12}>
                                <div className={classes.brand}>
                                    <h1 dangerouslySetInnerHTML={{__html: t('home.main.title')}}></h1>
                                    <h3 className={classes.title} dangerouslySetInnerHTML={{__html: t('home.main.description')}}>
                                    </h3>
                                    <Link to="/registration">
                                        <Button round className={classes.dlCTA}>
                                            <h5 className={classes.btnLabel}>
                                            {t('home.main.button')}
                                            </h5>
                                        </Button>
                                    </Link>
                                </div>
                            </GridItem>
                        </GridContainer>
                    </div>
                </Parallax>

                <div className={classNames(classes.main, classes.mainRaised)}>
                    <Section1 />
                </div>
                <div className={classNames(classes.main, classes.mainRaised)}>
                    <Section2 />
                </div>
                <div className={classNames(classes.main, classes.mainRaised)}>
                    <CtaSection CtaText={t('home.cta1.title')} CtaDescription={t('home.cta1.description')} CtaBtn={t('home.cta1.button')} CtaSubText={t("home.cta1.subtitle")} CtaUrl="/registration" CtaBgColor={"#00ada8"} />
                </div>
                <div className={classNames(classes.main, classes.mainRaised, classes.pb8)}>
                    <Section3 />
                </div>
                <div className={classNames(classes.main, classes.mainRaised)}>
                    <CtaSection CtaText={t('home.cta2.title')} CtaBtn={t('home.cta2.button')} CtaSubText={t("home.cta2.subtitle")} CtaUrl="/registration" CtaBgColor={"#00ada8"} />
                </div>
                <div className={classNames(classes.main, classes.mainRaised)}>
                    <PricingTable signupLink="/registration"/>
                </div>
                <Footer theme="white" content={<FooterContent />} />

            </div>
        )
    }
}
SlimFrontPage.propTypes = {
    classes: PropTypes.object.isRequired,
    width: PropTypes.string.isRequired,
};
//export default withStyles(frontpagestyle)(FrontPage);
export default compose(
    withStyles(frontpagestyle),
    withWidth(),
    withTranslation(),
)(SlimFrontPage);