import React from "react"
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import Card from "components/Card/Card.jsx";
import CardAvatar from "components/Card/CardAvatar.jsx";
import CardBody from "components/Card/CardBody.jsx";

//images
import CustomerIcon from "assets/img/mtt/benefits/customer.png";
import MoneyIcon from "assets/img/mtt/benefits/money.png";
import TimeIcon from "assets/img/mtt/benefits/time.png";
import ChartIcon from "assets/img/mtt/benefits/chart.png";
import { frontPageSectionStyle } from "assets/jss/material-kit-pro-react/views/frontPageSections/frontPageSectionStyle.jsx";
import { useTranslation } from "react-i18next";


export function Section1(props) {
    const { t } = useTranslation();
    const classes = frontPageSectionStyle()
        return (
            <div className={`${classes.section} ${classes.bgGrayGradient}`} style={{paddingBottom: "40px"}}>
                <div className={classes.container} style={{marginTop: "40px"}}>
                    
                    <h2 className={classes.title2} style={{padding: "40px 0", fontSize: "3.25rem", color: "#555"}}>{t('home.sec1.title')}</h2>
                
                    <GridContainer justify="space-evenly">
                        
                        <GridItem xs={12} sm={6} md={4} lg={3} style={{marginBottom: "20px"}}>
                           <Card profile style={{minHeight: "340px", minWidth: "150px"}}>
                            <CardAvatar className={classes.cardAvatarIcon} profile>
                                <img src={ChartIcon} alt="Save Time"/>
                            </CardAvatar>
                            <CardBody>
                                <h3 className={classes.cardTitle} style={{textAlign: "center", fontSize: "1.5rem"}} dangerouslySetInnerHTML={{__html: t('home.sec1.col1.title')}}></h3>
                                <p>{t('home.sec1.col1.desc')}</p>
                            </CardBody>
                           </Card> 
                        </GridItem>
                        
                        <GridItem xs={12} sm={6} md={3} lg={3} style={{marginBottom: "20px"}}>
                           <Card profile style={{minHeight: "340px", minWidth: "150px"}}>
                            <CardAvatar className={classes.cardAvatarIcon} profile>
                                <img src={CustomerIcon} alt="Reach Audience"/>
                            </CardAvatar>
                            <CardBody>
                                <h3 className={classes.cardTitle} style={{textAlign: "center", fontSize: "1.5rem"}} dangerouslySetInnerHTML={{__html: t('home.sec1.col2.title')}}></h3>
                                <p>{t('home.sec1.col2.desc')}</p>
                            </CardBody>
                           </Card> 
                        </GridItem>
                        
                        <GridItem xs={12} sm={6} md={3} lg={3} style={{marginBottom: "20px"}}>
                           <Card profile style={{minHeight: "340px", minWidth: "150px"}}>
                            <CardAvatar className={classes.cardAvatarIcon} profile>
                            <img src={MoneyIcon} alt="Reduce Cost"/>
                            </CardAvatar>
                            <CardBody>
                                <h3 className={classes.cardTitle} style={{textAlign: "center", fontSize: "1.5rem"}} dangerouslySetInnerHTML={{__html: t('home.sec1.col3.title')}}></h3>
                                <p>{t('home.sec1.col3.desc')}</p>
                            </CardBody>
                           </Card> 
                        </GridItem>
                        
                        <GridItem xs={12} sm={6} md={3} lg={3} style={{marginBottom: "20px"}}>
                           <Card profile style={{minHeight: "340px", minWidth: "150px"}}>
                            <CardAvatar className={classes.cardAvatarIcon} profile>
                                <img src={TimeIcon} alt="Grow Revenues"/>
                            </CardAvatar>
                            <CardBody>
                                <h3 className={classes.cardTitle} style={{textAlign: "center", fontSize: "1.5rem"}} dangerouslySetInnerHTML={{__html: t('home.sec1.col4.title')}}></h3>
                                <p>{t('home.sec1.col4.desc')}</p>
                            </CardBody>
                           </Card> 
                        </GridItem>
                        
                    </GridContainer>
                </div>
            </div>
        );
}