import {
    container,
    title,
    main,
    mainRaised,
} from "assets/jss/material-kit-pro-react.jsx";

import footerStyle from "assets/jss/material-kit-pro-react/views/componentsSections/footerStyle.jsx";


const frontPageStyle = {
    ...footerStyle,
    root: {
        background: "#fff"
    },
    main: {
        ...main
        /*overflow: "hidden"*/
    },
    mainRaised,
    parallax: {
        height: "100vh",
        overflow: "hidden"
    },
    container: {
        ...container,
        zIndex: 1
    },
    title: {
        ...title,
        fontWeight: "light",
        fontSize: "1.5rem",
        color: "#555",
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
    },
    brand: {
        color: "#00ada8",
        textAlign: "center",
        "& h1": {
            fontSize: "4.0rem",
            fontWeight: "light",
            display: "inline-block",
            position: "relative",
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
        }
    },
    btnLabel: {
        ...title,
        marginTop: "0",
        marginBottom: "0",
        color: "inherit",
        fontSize: "1.2rem",
        lineHeight: "2",
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
    },
    dlCTA: {
        background: "linear-gradient(60deg,#00ada8,#00ada8)",
        color: "#FFFFFF",
        marginLeft: "auto",
        marginRight: "auto",
        width: "280px",
        boxShadow: "0 14px 26px -12px rgba(68, 68, 68, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(68, 68, 68, 0.2)",
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        letterSpacing: "1px"
    },
    dlIcon: {
        marginLeft: "10px",
        marginTop: "-3px",
        height: "30px !important",
        width: "30px !important"
    },
    activateCTA: {
        color: "#00ada8",
        width: "180px",
        background: "#FFFFFF",
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
    },
    activateIcon: {
        marginLeft: "10px",
        marginTop: "-5px",
        height: "30px !important",
        width: "30px !important"
    },
    checkboxContainer: {
        width: "70%",
        margin: "auto",
        textAlign: "left",
        paddingLeft: "20px"
    },
    roundedInputLg: {
        display: 'flex',
        alignItems: 'center',
        width: "70%",
        margin: "20px auto 30px",
        background: "#fff",
        border: "0 none",
        borderRadius: "50px",
        padding: "0 20px",
        fontSize: "0.95rem",
        height: "55px",
        boxShadow: "0 -2px 3px 0px rgba(68, 68, 68, 0.1) inset, 0 0 2px 0px rgba(0, 0, 0, 0.1) inset, 0 8px 8px -5px rgba(68, 68, 68, 0.2) inset",
        "&::-webkit-input-placeholder": {
            color: "#999",
            opacity: "1"
        },
        "&::-moz-placeholder": {
            color: "#999",
            opacity: "1"
        },
        "&:focus::-moz-placeholder": {
            opacity: "0"
        },
        "& div": {
            marginLeft: "10px",
            flex: 1,
        },
        "& button": {
            padding: 10,
            color: "#555"
        },
        "& hr": {
            height: 28,
            margin: 4,
        },
    },
    btnDivider: {
        fontWeight: 600
    },
    feedForm: {
        "& > div": {
            marginBottom: "15px"
        }
    },
    inputInfo: {
        margin: "0 0 30px",
        display: "inline-block",
        color:  "#888",
        fontSize: "0.9rem"
    },
    lmCTA: {
        display: "block",
        color:  "#00ada8",
        fontSize: "1.1rem",
        fontWeight: 600,
        letterSpacing: "1px",
        marginTop: "30px",
        "&:hover": {
            color: "#555"
        }
    },
    pb8: {
        paddingBottom: "64px"
    },
    pt8: {
        paddingTop: "64px"
    }
};

export default frontPageStyle;