// nodejs library that concatenates classes
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import withWidth from '@material-ui/core/withWidth';
import frontpagestyle from "assets/jss/material-kit-pro-react/views/frontPageStyle.jsx";
import classNames from "classnames";
import Button from "components/CustomButtons/Button.jsx";
import Footer from "components/Footer/Footer.jsx";
import FooterContent from "components/Footer/FooterContent.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
//import { Link } from "react-router-dom";
// core components
import SlimHeader from "components/Header/SlimHeader.jsx";
import SlimHeaderLinks from "components/Header/SlimHeaderLinks.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
import PropTypes from 'prop-types';
import React from "react";
import { withTranslation } from 'react-i18next';
import compose from 'recompose/compose';
import { CtaSection } from "./Section/CtaSection";
import { Section1 } from "./Section/Section1";
import { Helmet } from "react-helmet";
import { Section2 } from "views/EarlyBirdRegistrationPage/Section/Section2"

class B2CPage extends React.Component {

    componentDidMount() {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
    }

    render() {
        const { classes, t } = this.props;
        return (
            <div className={classes.root}>
                <Helmet>
                    <title>{t("b2c.meta.title")}</title>
                    <meta name="description" content={t("b2c.meta.desc.short")} />
                    <link rel="canonical" href="https://feed.studio/promotion" />
                    <meta name="robots" content="index,follow" />
                    <meta itemprop="name" content={t("b2c.meta.title")} />
                    <meta itemprop="description" content={t("b2c.meta.desc.short")} />
                    <meta itemprop="image" content="http://www.example.com/image.jpg" />
                    <meta name="twitter:card" content="http://example.com/image.jpg" />
                    <meta name="twitter:site" content="@twitter" />
                    <meta name="twitter:title" content={t("b2c.meta.title")} />
                    <meta name="twitter:description" content={t("b2c.meta.desc.long")} />
                    <meta name="twitter:creator" content="@twitter" />
                    <meta name="twitter:image:src" content="http://www.example.com/image.jpg" />
                    <meta property="og:title" content={t("b2c.meta.title")}/>
                    <meta property="og:type" content="website" />
                    <meta property="og:url" content="https://feed.studio/promotion" />
                    <meta property="og:image" content="http://example.com/image.jpg" />
                    <meta property="og:description" content={t("b2c.meta.desc.short")} />
                    <meta property="og:site_name" content="Feed Studio" />
                </Helmet>
                <SlimHeader brand="Feed.Studio"  fixed color="transparent" changeColorOnScroll={{ height: 200, color: "white" }} links={<SlimHeaderLinks dropdownHoverColor="info" />} />

                <Parallax image={require("assets/img/mtt/plexus_1.jpg")} className={classes.parallax}>
                    <div className={classes.container} style={{ marginTop: "-100px" }}>
                        <GridContainer justify="center" alignItems="center">
                            <GridItem xs={12} lg={12}>
                                <div className={classes.brand}>
                                    <h1 dangerouslySetInnerHTML={{__html: t('b2c.main.title')}}></h1>
                                    <h3 className={classes.title} dangerouslySetInnerHTML={{__html: t('home.main.description')}}>
                                    </h3>
                                    <Button round className={classes.dlCTA}>
                                        <h5 className={classes.btnLabel}>
                                        {t('home.main.button')}
                                        </h5>
                                    </Button>
                                </div>
                            </GridItem>
                        </GridContainer>
                    </div>
                </Parallax>

                <div className={classNames(classes.main, classes.mainRaised)}>
                    <CtaSection CtaText={t('b2c.cta1.title')} CtaDescription={t('b2c.cta1.description')} CtaBtn={t('b2c.cta1.button')} CtaUrl={"//wordpress.org/plugins/gplugin/"} CtaBgColor={"#00ada8"} />
                </div>
                <div className={classNames(classes.main, classes.mainRaised)}>
                    <Section1 />
                </div>
                <div className={classNames(classes.main, classes.mainRaised)}>
                    <Section2 />
                </div>
                <Footer theme="white" content={<FooterContent />} />

            </div>
        )
    }
}
B2CPage.propTypes = {
    classes: PropTypes.object.isRequired,
    width: PropTypes.string.isRequired,
};
//export default withStyles(frontpagestyle)(FrontPage);
export default compose(
    withStyles(frontpagestyle),
    withWidth(),
    withTranslation(),
)(B2CPage);