import {
    container,
    title
} from "assets/jss/material-kit-pro-react.jsx";
  

const styles = {
    container,
    title,
    title2: {
        ...title,
        color: "#3c4858",
        textAlign: "center",
        paddingBottom: "40px"
    },
    paper: {
        marginBottom: "40px",
        boxShadow: "none"
    },
    pricingTable: {
        borderSpacing: "15px 0",
        borderCollapse: "separate",
        "& thead th": {
            background: "#f3f3f3",
            color: "#555",
            fontSize: "1.2rem",
            fontWeight: "500",
            textTransform: "uppercase",
            textAlign: "center",
            padding: "4px 24px 4px 24px"
        },
        "& tbody td, & tfoot td": {
            textAlign: "center",
            padding: "4px 24px 4px 24px",
            color: "#555"
        },
        "& tfoot td": {
            background: "#fff",
            fontSize: "1.1rem",
            fontWeight: "500"
        },
        "& svg": {
            position: "relative",
            top: "3px",
            width: "24px",
            height: "24px",
            color: "#999"
        }
    },
    Btn: {
        margin: "15px 0 25px"
    },
    textLeft: {
        textAlign: "left !important"
    },
    textRight: {
        textAlign: "right !important"
    },
    textCenter: {
        textAlign: "center !important"
    },
    boxShadow: {
        boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12)"
    },
    yesIcon: {
        color: "#00ada8 !important"
    }
};
export default styles;