import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";

import buttonStyle from "assets/jss/material-kit-pro-react/components/buttonStyle.jsx";

class GoogleSignInButton extends React.Component {
    
    state = {
        gapiLoaded: false
    }

    componentDidMount() {
        this.initGapi()
    }

    initGapi() {
        if(window.gapi === undefined || window.gapi.auth2 === undefined) {
            setTimeout(() => this.initGapi(), 100)
        } else {
            this.setState({gapiLoaded: true})
        }
    }

    onGoogleSignIn(e) {
        let authInst = window.gapi.auth2.getAuthInstance()
        authInst.grantOfflineAccess({ 
            scope: 'https://www.googleapis.com/auth/adwords'
        }).then(res => {
            if(this.props.onSignInSuccessful !== undefined && typeof this.props.onSignInSuccessful === 'function') {
                this.props.onSignInSuccessful(res)
            }
        })
        .catch((e) => console.log(e));
    }

    render() {

        const {
            classes,
            color,
            round,
            children,
            fullWidth,
            disabled,
            simple,
            size,
            block,
            link,
            justIcon,
            fileButton,
            className,
            onSignInSuccessful,
            ...rest
        } = this.props;

        var btnClasses = classNames({
            [classes.button]: true,
            [classes[size]]: size,
            [classes[color]]: true,
            [classes.round]: true,
            [classes.fullWidth]: fullWidth,
            [classes.disabled]: disabled || !this.state.gapiLoaded,
            [classes.simple]: simple,
            [classes.block]: block,
            [classes.link]: link,
            [classes.justIcon]: false,
            [classes.fileButton]: fileButton,
            [className]: className
        });
        return (
            <Button type="button" {...rest} className={btnClasses} onClick={(e) => this.onGoogleSignIn(onSignInSuccessful)} style={{border:"1px solid #ddd"}}>
                {/*<i className={" fab fa-google"} /> */}
                {children}
            </Button>
        );
    }

}

GoogleSignInButton.propTypes = {
    classes: PropTypes.object.isRequired,
    color: PropTypes.oneOf([
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "danger",
        "rose",
        "white",
        "twitter",
        "facebook",
        "google",
        "linkedin",
        "pinterest",
        "youtube",
        "tumblr",
        "github",
        "behance",
        "dribbble",
        "reddit",
        "instagram",
        "transparent",
        "heroBlue"
    ]),
    size: PropTypes.oneOf(["sm", "lg"]),
    simple: PropTypes.bool,
    round: PropTypes.bool,
    fullWidth: PropTypes.bool,
    disabled: PropTypes.bool,
    block: PropTypes.bool,
    link: PropTypes.bool,
    justIcon: PropTypes.bool,
    fileButton: PropTypes.bool
};

export default withStyles(buttonStyle)(GoogleSignInButton);
