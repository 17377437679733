import "assets/scss/material-kit-pro-react.css?v=1.2.0";
import "assets/scss/responsive.css?v=1.0";
import { createBrowserHistory, History } from "history";
import i18n, { TFunction } from "i18next";
import i18nInstance from "locales/i18n";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Helmet } from "react-helmet";
import { withTranslation } from "react-i18next";
import { Provider } from 'react-redux';
import { Route, Router, Switch } from "react-router";
import { doRefreshUser, requestSignInWithAccessToken } from "redux/actions";
import { globalStore } from 'redux/AppStore';
import indexRoutes from "routes/index";
import { ThemeProvider } from "@material-ui/core";
import ReactGA from "react-ga"
import { theme as adminTheme } from "assets/dashboard/jss/material-dashboard-react"


if (parseInt(process.env.REACT_APP_API_MOCK || "0") === 1) {
    require('MockServer')
}

type AppProps = {
    i18n: typeof i18n,
    tReady: boolean,
    t: TFunction
}

type AppState = {
    gapiLoaded: boolean,
    history: History
}

class App extends React.Component<AppProps, AppState> {
    state: AppState = {
        gapiLoaded: false,
        history: createBrowserHistory({
            basename: i18nInstance.language !== "en" ? i18nInstance.language : ""
        })
    }

    constructor(props: AppProps) {
        super(props);
        this.init()
    }

    async wait(ms:number) {
        return new Promise(resolve => {
            setTimeout(resolve, ms);
        });
    }

    async init() {
        ReactGA.initialize("UA-42995494-15")
        ReactGA.pageview(window.location.pathname + window.location.search);

        this.attachAnalyticsHistoryListener()
        this.reconcileUrl()
        i18nInstance.on("languageChanged", () => this.reconcileUrl())
        await this.initGoogleAuth()

        let user = localStorage.getItem("user")
        this.refreshUser(user)
    }

    componentDidUpdate(prevProps: AppProps, prevState: AppState) {
        if(prevState.history !== this.state.history) {
            this.attachAnalyticsHistoryListener()
        }
    }

    attachAnalyticsHistoryListener() {
        this.state.history.listen(location => {
            ReactGA.set({ page: window.location.pathname }); // Update the user's current page
            ReactGA.pageview(window.location.pathname); // Record a pageview for the given page
        });
    }

    reconcileUrl() {
        const oldPathName = window.location.pathname
        const path = oldPathName.replace(/^\/+|\/+$/g, '').split('/')
        
        console.log({path, lang: i18nInstance.language})
        if(path.length) {
            if(path[0] !== i18nInstance.language) {
                if(Object.keys(i18n.options.resources!).includes(path[0])) {
                    path.shift()
                }

                if(i18nInstance.language !== "en") {
                    path.unshift(i18nInstance.language)
                }

                const newPathName = "/"+path.join('/')

                if(oldPathName !== newPathName) {
                    window.location.pathname = newPathName
                }
            }
        }
    }

// @ts-ignore
    async refreshUser(user) {
        if (user) {
            try {
                user = JSON.parse(user)
                await globalStore.dispatch(doRefreshUser(user))
            }
            catch (e) {
                localStorage.removeItem("user")
                user = undefined
            }
        }

        if(!user) {
// @ts-ignore
            let authInstanceOrPromise = window.gapi.auth2.getAuthInstance()
// @ts-ignore
            authInstanceOrPromise.then(async (authInstance) => {
                if(authInstance.isSignedIn.get() === true) {
                    let user = authInstance.currentUser.get()
                    let authResponse = user.getAuthResponse(true)
                    let accessToken = authResponse.access_token
                    await globalStore.dispatch(requestSignInWithAccessToken(accessToken))
                }
            })
            
        }
    }

    async initGoogleAuth() {
        // @ts-ignore
        while(window.gapi === undefined) {
            await this.wait(100)
        }

        return new Promise(resolve => {
            // @ts-ignore
            if (window.gapi.auth2 === undefined) {
                // @ts-ignore
                window.gapi.load('auth2', () => {
                    // @ts-ignore
                    this.auth2 = window.gapi.auth2.init({
                        cookiepolicy: 'single_host_origin'
                    });
    
                    this.setState({ gapiLoaded: true })
                    resolve()
                });
            }
        })
        
    }


    render() {
        const { t } = this.props
        const AdminComponent = React.lazy(() => import("layouts/Admin"))

        return (
            <Router history={this.state.history}>
                <Helmet>
                    <title>{t("home.meta.title")}</title>
                    <meta name="description" content={t("home.meta.desc.short")} />
                    {/* @ts-ignore */}
                    <meta itemprop="name" content={t("home.meta.title")} />
                    {/* @ts-ignore */}
                    <meta itemprop="description" content={t("home.meta.desc.short")} />
                    {/* @ts-ignore */}
                    <meta itemprop="image" content="http://www.example.com/image.jpg" />
                    <meta name="twitter:card" content="http://example.com/image.jpg" />
                    <meta name="twitter:site" content="@twitter" />
                    <meta name="twitter:title" content={t("home.meta.title")} />
                    <meta name="twitter:description" content={t("home.meta.desc.long")} />
                    <meta name="twitter:creator" content="@twitter" />
                    <meta name="twitter:image:src" content="http://www.example.com/image.jpg" />
                    <meta property="og:title" content={t("home.meta.title")}/>
                    <meta property="og:image" content="http://example.com/image.jpg"/>
                    <meta property="og:description" content={t("home.meta.desc.short")}/>
                </Helmet>
                <Provider store={globalStore}>
                    <Suspense fallback={<div></div>}>
                        
                        
                            <Switch>
                                    <Route path="/admin" key="admin" component={() => <ThemeProvider theme={adminTheme}><AdminComponent /></ThemeProvider>} />
                                    {indexRoutes.map((prop, key) => {
                                        return <Route path={prop.path} key={key} component={prop.component} />;
                                    })}
                            </Switch>
                        
                        
                    </Suspense>
                </Provider>
            </Router>
        );
    }
}

let AppInstance = withTranslation()(App)

ReactDOM.render(
    <AppInstance />, document.getElementById("root")
);