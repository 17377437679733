import UserService from "services/UserService"
import ProjectService from "services/ProjectService"

export const types = {
    REQUEST_SIGN_IN: "REQUEST_SIGN_IN",
    REQUEST_SIGN_IN_ACCESS_TOKEN: "REQUEST_SIGN_IN_ACCESS_TOKEN",
    REQUEST_USER_DATA: "REQUEST_USER_DATA",
    SIGN_IN_SUCCESSFUL: "SIGN_IN_SUCCESSFUL",
    SELECT_PROJECT: "SELECT_PROJECT",
    REFRESH_USER: "REFRESH_USER",
    REFRESH_PROJECTS: "REFRESH_PROJECTS",
    FETCH_CATALOGUE: "FETCH_CATALOGUE",
    SELECT_CATALOGUE: "SELECT_CATALOGUE"
}

export function selectProject(project) {
    return {
        type: types.SELECT_PROJECT,
        project
    }
}
export function signInSuccessful(user) {
    return {
        type: types.SIGN_IN_SUCCESSFUL,
        user
    }
}

export function refreshUser(user) {
    return {
        type: types.REFRESH_USER,
        user
    }
}

export function refreshProjects(projects) {
    return {
        type: types.REFRESH_PROJECTS,
        projects
    }
}

export function fetchCatalogues(catalogues) {
    return {
        type: types.FETCH_CATALOGUE,
        catalogues
    }
}

export function selectCatalogue(catalogue) {
    return {
        type: types.SELECT_CATALOGUE,
        catalogue
    }
}

export function doFetchCatalogue(user, project) {
    return async (dispatch) => {
        const projectService = new ProjectService(user)
        let cataloguesResponse = await projectService.getCatalogues(project.id, 999)

        if(cataloguesResponse.data.data.length >= 1) {
            dispatch(fetchCatalogues(cataloguesResponse.data.data))
        }

    }
}

export function doRefreshProjects(user) {
    return async (dispatch) => {
        const userService = new UserService(user)
        let projectsResponse = await userService.getProjects(user.id)

        dispatch(refreshProjects(projectsResponse.data.data))
    }
}

export function requestUserData(user) {
    return async (dispatch) => {
        dispatch({
            type: types.REQUEST_USER_DATA,
            user
        })

        const userService = new UserService(user)
        let userResponse = await userService.getUserData(user.id)
        let userdata = userResponse.data.data

        userdata.token = user.token

        return userdata
    }
}

export function doRefreshUser(user) {
    return async (dispatch) => {
        let userdata = await dispatch(requestUserData(user))

        dispatch(refreshUser(userdata))
    }
}

export function requestSignIn(authCode) {
    return async (dispatch) => {
        dispatch({
            type: types.REQUEST_SIGN_IN,
            authCode
        })

        const userService = new UserService(null)

        try {
            let response = await userService.googleAuth(authCode)
            let user = response.data.data
            

            let userdata = await dispatch(requestUserData(user))

            dispatch(signInSuccessful(userdata))

            return userdata
        }
        catch(e) {
            console.log(e)
        }
    }
}

export function requestSignInWithAccessToken(accessToken) {
    return async (dispatch) => {
        dispatch({
            type: types.REQUEST_SIGN_IN_ACCESS_TOKEN,
            accessToken
        })

        const userService = new UserService(null)

        try {
            let response = await userService.authWithAccessToken(accessToken)
            let user = response.data.data
            
            let userdata = await dispatch(requestUserData(user))

            dispatch(signInSuccessful(userdata))

            return userdata
        }
        catch(e) {
            console.log(e)
        }
    }
}