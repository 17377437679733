import { types } from 'redux/actions';

const initialState = {
    loading: true,
    user: null,
    selectedProject: {id: -1},
    projects: [],
    projectsLoaded: false,
    selectedCatalogue: null,
    catalogues: [],
    cataloguesLoaded: false
};

function rootReducer(state = initialState, action) {
    switch (action.type) {
        case types.SIGN_IN:
            return Object.assign({}, state, {
                loading: true
            });
        case types.SIGN_IN_SUCCESSFUL:
            return Object.assign({}, state, {
                user: action.user,
                loading: false
            })
        case types.SELECT_PROJECT:
            return Object.assign({}, state, {
                selectedProject: action.project,
                selectedCatalogue: null,
                cataloguesLoaded: false,
            })
        case types.REFRESH_USER:
            return Object.assign({}, state, {
                user: action.user,
                loading: false
            })
        case types.REFRESH_PROJECTS:
            return Object.assign({}, state, {
                projects: action.projects,
                projectsLoaded: true
            })
        case types.FETCH_CATALOGUE:
            return Object.assign({}, state, {
                selectedCatalogue: null,
                catalogues: action.catalogues,
                cataloguesLoaded: true
            })
        case types.SELECT_CATALOGUE:
            return Object.assign({}, state, {
                selectedCatalogue: action.catalogue
            })

        default:
            return state;
    };
}

export default rootReducer;